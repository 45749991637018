import React, { Component } from "react";

import { Link, Redirect } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { toast } from "react-toastify";
import Url from "app/shared/constants/Url";
import axios from "axios";
import { UPDATE_PERMITTED_MODULE_LIST } from "app/redux/constant/ActionType";
import { connect } from "react-redux";
import { Eye } from "react-feather";
import { TIMEZONE_STR } from "app/shared/utility/helperStrings";
import moment from "moment";
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import AlertMessage from "app/shared/components/Popups/AlertMessage";
const qs = require("qs");

const alertMsg = (props) => {
  toast.error(props.message, {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    showHidePassword: false,
  });
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateModuleList: (payloadData) => {
      dispatch({ type: UPDATE_PERMITTED_MODULE_LIST, payload: payloadData });
    },
  };
};

class LoginForm extends Component {
  state = {
    type: 1,
    formSubmitting: false,
    redirectToReferrer: false,
    showOtpSection: false,  
    enteredOtp: "",
    prevPath: null,
    otp: ["", "", "", "", "", ""], 
    isResendDisabled: true, 
    timer: 15,
  };

  componentWillReceiveProps(nextProps) {
    this.setState({ prevPath: nextProps.location });
  }

  changeShowHidePassword = async () => {
    await this.setState({
      showHidePassword: !this.state.showHidePassword,
    });
  };


  verifyOtp = () => {
    const enteredOtp = this.state.otp.join(""); 
  
    if (enteredOtp.length !== 6) {
      alertMsg({ message: "Please enter the full 6-digit OTP!" });
      return;
    }
  
    const requestBody = {
      otp: enteredOtp,
      user_id: this.state.userId,
      temp_authkey: this.state.tempAuthKey,
    };
    axios
      .post(
        Url.otpverification,
        qs.stringify(requestBody),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((res) => {
        if (res.data.status === 200) {
          const { data } = res.data;
          const { kpi_flag, overview_flag } = data;
          let tempList = [...data.module];

          if (kpi_flag) {
            tempList = [
              ...tempList,
              {
                module_name: "kpi",
                status: kpi_flag,
              },
            ];
          }

          if (overview_flag) {
            tempList = [
              ...tempList,
              {
                module_name: "overview",
                status: overview_flag,
              },
            ];
          }

          this.props.updateModuleList(
            tempList.filter((item) => item.status)
          );
          const userTZ =
            data.timezone ||
            Intl.DateTimeFormat().resolvedOptions().timeZone;

          localStorage.setItem("userData", JSON.stringify(data));
          localStorage.setItem("userId", data.user_id);
          localStorage.setItem("token", data.auth_key);
          localStorage.setItem(TIMEZONE_STR, userTZ);
          moment.tz.setDefault(userTZ);
          this.setState({ redirectToReferrer: true });
          AlertMessage({ message: "OTP verified successfully!", type: "success" });
  
        } else {
          alertMsg({ message: res.data.msg });
          this.setState({ otp: ["", "", "", "", "", ""] });
        }
      })
      .catch(() => {
        alertMsg({ message: "OTP verification failed. Please try again!" });
      });
  };
  
  


  componentDidMount() {
    this._getLogo();
  }

  startResendTimer = () => {
    this.setState({ isResendDisabled: true, timer: 15 });
    const interval = setInterval(() => {
      this.setState((prevState) => {
        if (prevState.timer === 1) {
          clearInterval(interval);
          return { isResendDisabled: false, timer: 0 };
        }
        return { timer: prevState.timer - 1 };
      });
    }, 1000);
  };

  handleChange = (index, event) => {
    const value = event.target.value;
    if (!/^\d?$/.test(value)) return; 

    let newOtp = [...this.state.otp];
    newOtp[index] = value;
    this.setState({ otp: newOtp });

    if (value && index < 5) {
      document.getElementById(`otp-input-${index + 1}`).focus();
    }

  };

  handleKeyDown = (index, event) => {
    if (event.key === "Backspace") {
      let newOtp = [...this.state.otp];

      if (!newOtp[index] && index > 0) {
        document.getElementById(`otp-input-${index - 1}`).focus();
      }

      newOtp[index] = ""; 
      this.setState({ otp: newOtp });
    }
  };

  handleResend = () => {
    console.log("Resending OTP...");
    this.startResendTimer();
  };

  _getLogo = async () => {
    const requestData = {
      status: 1,
      link: Url.clientURL,

    };
  
    import("app/shared/utility/UtilFunction").then((utilFunction) => {
      let responseObj = utilFunction.sharedAxiosAPI(
        Url.compnayLogo,
        undefined,
        requestData
      );
  
      responseObj
        .then(async (response) => {
          if (response.data.status === 200) {
            const loginType = response.data.data.login_type;
            this.setState({ type: loginType }); 
          }
        })
        .catch((error) => console.log("Error fetching loginType:", error));
    });
  };
  
  resendOtp = async () => {
    this.startResendTimer();
    let requestData = {
      temp_authkey: this.state.tempAuthKey,
      user_id: this.state.userId,
    };
  
    let URL = Url.resendOtp;
    const contentType = {
      "Content-Type": "application/x-www-form-urlencoded",
    };
  
    console.log("Request Payload:", requestData);
  
    await axios
      .post(URL, qs.stringify(requestData), { headers: contentType })
      .then(async (response) => {
        if (response.data.status === 200) {
          AlertMessage({ message: "OTP has been sent", type: "success" });
        } else {
          this.setState({ formSubmitting: false });
        }
      })
      .catch(async (error) => {
        this.setState({ dataLoading: false });
      });
  };

  render() {
    if (localStorage.getItem("token") !== null) {
      return <Redirect to={this.state.prevPath} />;
    }

    if (this.state.redirectToReferrer) {
      return <Redirect to={"/"} />;
    }
    const {t} = this.props;
    return (
      <div className="login-form">
        <div className="sign-header-section">
          <div className="sign-in-title" style={{ padding: "3% 0", margin: 0 }}>
            {t('login')}
          </div>
        </div>

        {!this.state.showOtpSection ? (
        // <Formik
        //   initialValues={{ email: "", password: "" }}
        //   validate={(values) => {
        //     let errors = {};
        //     if (values.email === "") {
        //       errors.email = t("userNameRequired");
        //     } else if (
        //       !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
        //     ) {
        //       errors.email = t("invalidEmailAddressFormat");
        //     }
        //     if (values.password === "") {
        //       errors.password = t("passwordRequired");
        //     }
        //     return errors;
        //   }}
        //   onSubmit={async (values, actions) => {
        //     this.setState({ formSubmitting: true });
        //   //   axios
        //   //     .post(
        //   //       Url.login,
        //   //       qs.stringify({
        //   //         user_name: values.email,
        //   //         password: values.password,
        //   //         otp_status: 1,
        //   //       }),
        //   //       {
        //   //         headers: {
        //   //           "Content-Type": "application/x-www-form-urlencoded",
        //   //         },
        //   //       }
        //   //     )
        //   //     .then((res) => {
        //   //       if (res.data.status == 200) {
        //   //         const { data } = res.data;
        //   //         const { kpi_flag, overview_flag } = data;
        //   //         let tempList = [...data.module];

        //   //         if (kpi_flag) {
        //             // tempList = [
        //             //   ...tempList,
        //             //   {
        //             //     module_name: "kpi",
        //             //     status: kpi_flag,
        //             //   },
        //             // ];
        //   //         }

        //   //         if (overview_flag) {
        //             // tempList = [
        //             //   ...tempList,
        //             //   {
        //             //     module_name: "overview",
        //             //     status: overview_flag,
        //             //   },
        //             // ];
        //   //         }

        //   //         this.props.updateModuleList(
        //   //           tempList.filter((item) => item.status)
        //   //         );
        //   //         // Delay the reload to ensure the update happens first
        //   //         // setTimeout(() => {
        //   //         //   window.location.reload();
        //   //         // }, 500); 
        //   //         // setTimeout(() => {
        //   //         //   this.setState({ redirectToReferrer: true });
        //   //         // }, 3000);
        //   //         const userTZ =
        //   //           data.timezone ||
        //   //           Intl.DateTimeFormat().resolvedOptions().timeZone;

        //   //         localStorage.setItem("userData", JSON.stringify(data));
        //   //         localStorage.setItem("userId", data.user_id);
        //   //         localStorage.setItem("token", data.auth_key);
        //   //         localStorage.setItem(TIMEZONE_STR, userTZ);
        //   //         moment.tz.setDefault(userTZ);

        //   //       } else {
        //   //         alertMsg({ message: res.data.msg });
        //   //       }
        //         // this.setState({ formSubmitting: false });
        //   //     })
        //   //     .catch((error) => {
        //   //       this.setState({ formSubmitting: false });
        //   //     });

        //   this.setState({ showOtpSection: true, formSubmitting: false });

        //   }}
        // >

        <Formik
          initialValues={{ email: "", password: "", type: this.state.type }}
          enableReinitialize={true}
          validate={(values) => {
            let errors = {};
            if (!values.email) {
              errors.email = t("userNameRequired");
            } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
              errors.email = t("invalidEmailAddressFormat");
            }
            if ((values.type === 1 || values.type === 3) && !values.password) {
              errors.password = t("passwordRequired");
            }
            return errors;
          }}
          onSubmit={async (values, actions) => {
            this.setState({ formSubmitting: true });

            let requestBody = {
              type: values.type,
              email: values.email,
            };

            if (values.type === 1 || values.type === 3) {
              requestBody.password = values.password;
            }

            axios
              .post(
                Url.loginn,
                qs.stringify(requestBody),
                {
                  headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                  },
                }
              )
              .then((res) => {
                if (res.data.status === 200) {
                  const { data } = res.data;
                  localStorage.setItem("login_type", data.login_type);

                  if (values.type === 2 || values.type === 3) {
                    this.setState({ 
                      showOtpSection: true, 
                      tempAuthKey: data.tempAuthKey,
                      userId: data.user_id,
                    });
                    this.startResendTimer();
                  } else {
                    const { kpi_flag, overview_flag } = data;
                    let tempList = [...data.module];

                    if (kpi_flag) {
                      tempList = [
                        ...tempList,
                        {
                          module_name: "kpi",
                          status: kpi_flag,
                        },
                      ];
                    }

                    if (overview_flag) {
                      tempList = [
                        ...tempList,
                        {
                          module_name: "overview",
                          status: overview_flag,
                        },
                      ];
                    }

                    this.props.updateModuleList(
                      tempList.filter((item) => item.status)
                    );
                    localStorage.setItem("userData", JSON.stringify(data));
                    localStorage.setItem("userId", data.user_id);
                    localStorage.setItem("token", data.auth_key);
                    this.setState({ formSubmitting: false });
                  }
                } else {
                  alertMsg({ message: res.data.msg });
                  this.setState({ formSubmitting: false });
                }
              })
              .catch(() => {
                this.setState({ formSubmitting: false });
              });
          }}
        >


          {({
            touched,
            errors,
            isSubmitting,
            handleSubmit,
            isValid,
            dirty,
            values,
          }) => (
            <Form onSubmit={handleSubmit} className="input-container">
              <div className="input-group">
                <Field
                  name="email"
                  type="email"
                  placeholder={t('userName')}
                  className="w-100 border-0"
                />
                <ErrorMessage
                  component="div"
                  name="email"
                  className="inputErrorMsg text-danger"
                />
              </div>
              {this.state.type !== 2 && (
                <div className="input-group">
                  <div className="password-container">
                    <Field
                      type={this.state.showHidePassword ? "text" : "password"}
                      name="password"
                      placeholder={t('password')}
                      className="w-100 border-0"
                    />

                    <span className="toggle-password">
                      <Eye
                        style={{ color: "grey", height: "20", width: "20" }}
                        onClick={this.changeShowHidePassword}
                      ></Eye>
                    </span>
                  </div>
                  <ErrorMessage
                    component="div"
                    name="password"
                    className="inputErrorMsg text-danger"
                  />
                </div>
              )}
              {this.state.type !== 2 && (
              <div className="forget-div">
                <Link className="color-primary text-decoration-none" to={`/auth/forget-password`}>{t('forgotPassword')}</Link>
              </div>
            )}
              <button
                type="submit"
                disabled={!isValid || this.state.formSubmitting || !dirty}
              >
                {this.state.formSubmitting ? t('pleaseWait') : t('login')}
              </button>
            </Form>
          )}
        </Formik>
        ) 
        : (
          <div style={{ textAlign: "center", marginTop: "20px" }}>
            <h3 style={{ fontSize: "18px", fontWeight: "bold", color: "#333", marginBottom: "10px" }}>
              Enter OTP
            </h3>

            {/* OTP Input Fields in a Single Row */}
            <div style={{ display: "flex", justifyContent: "center", gap: "8px" }}>
              {this.state.otp.map((digit, index) => (
                <input
                  key={index}
                  id={`otp-input-${index}`}
                  type="text"
                  value={digit}
                  onChange={(e) => this.handleChange(index, e)}
                  onKeyDown={(e) => this.handleKeyDown(index, e)}
                  maxLength={1}
                  style={{
                    width: "40px",
                    height: "40px",
                    fontSize: "20px",
                    textAlign: "center",
                    border: "1px solid #ccc",
                    borderRadius: "5px",
                    outline: "none",
                    transition: "all 0.2s ease-in-out"
                  }}
                  onFocus={(e) => (e.target.style.borderColor = "#007bff")}
                  onBlur={(e) => (e.target.style.borderColor = "#ccc")}
                />
              ))}
            </div>

            {/* Verify OTP Button */}
            <button
              onClick={this.verifyOtp}
              disabled={this.state.otp.includes("")}  // Button disabled if any field is empty
              style={{
                marginTop: "15px",
                padding: "10px 15px",
                fontSize: "16px",
                border: "none",
                borderRadius: "5px",
                cursor: this.state.otp.includes("") ? "not-allowed" : "pointer",
                backgroundColor: this.state.otp.includes("") ? "#ccc" : "#ff644e",
                color: "white"
              }}
            >
              Verify OTP
            </button>

            {/* Resend OTP Button with Timer */}
            <button
              onClick={this.resendOtp}
              disabled={this.state.isResendDisabled}
              style={{
                marginTop: "10px",
                marginLeft: "10px",
                padding: "10px 15px",
                fontSize: "16px",
                border: "none",
                borderRadius: "5px",
                cursor: this.state.isResendDisabled ? "not-allowed" : "pointer",
                backgroundColor: this.state.isResendDisabled ? "#ccc" : "#28a745",
                color: "white"
              }}
            >
              Resend OTP {this.state.isResendDisabled ? `(${this.state.timer}s)` : ""}
            </button>
          </div>
        )
        }
      </div>
    );
  }
}

export default compose(
  connect(null, mapDispatchToProps),
  withTranslation(["util"])
)(LoginForm);
