/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import { Scrollbars } from "react-custom-scrollbars";
import _ from "lodash";
import FishboneChart from "fishbone-chart";
// ui kit components
import {
  File,
  Files,
  Tooltip,
  Carousel,
  Input,
  Combobox,
  Checkbox,
} from "@salesforce/design-system-react";
import { Eye } from "react-feather";
//components
import ClideLoader from "app/shared/ui-kit/loader";
import MyDropzone from "app/shared/components/DocumentModal/Dropzone";
//assets
import {
  PlusIconRed,
  RoundSave,
  CrossRound,
  RoundDelete,
  RoundEdit,
  TooltipInfoIcon,
} from "assets/Iconsv2/Iconsv2";
import { Upload } from "react-feather";
import { useTranslation } from "react-i18next";

const DetailStep3 = ({ state, functionContent, variablesObj }) => {
  const {
    isInprogress,
    isApproveEnabled,
    iraDetailStep3,
    dataGathering,
    isCompleted,
  } = variablesObj ?? {};
  const { witness, posts = {}, cost = {}, analysis = {} } = dataGathering ?? {};
  const { costList = [] } = iraDetailStep3?.cost_details ?? {};

  const capitalizeFirstLetter = (string = "") => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  function combineArrays(arr1 = [], arr2 = []) {
    // Check if either array is empty
    if (arr1.length === 0 && arr2.length === 0) {
      return [];
    } else if (arr1.length === 0) {
      return arr2.slice(); // Return a copy of arr2 if arr1 is empty
    } else if (arr2.length === 0) {
      return arr1.slice(); // Return a copy of arr1 if arr2 is empty
    }

    // Combine arrays if both are non-empty
    return arr1.concat(arr2);
  }
  const {t} = useTranslation(['ira', 'util']);
  const postTooltip = {
    paper: t('paperTooltip'),
    physcial: t('physcialTooltip'),
    position: 
      t('positionTooltip'),
    sketch: t('sketchTooltip'),
  };

  const postsUpload = combineArrays(posts?.savedPalyoad, posts?.files);

  return (
    <div>
      <div className="row mt-3">
        <div className="col-12 col-md-12 col-lg-12">
          <div className="col-12 col-lg-12 col-md-12 d-flex justify-content-between px-0">
            <div className="clide-v2-title mb-1">
              <label className="clide-v2-fs-4 clide-v2-primary-color fw-bold pt-3 mb-4">
                {" "}
                {t('step3DataGathering')}
              </label>
            </div>
            
            <div className="d-flex">
            <div className="clide-v2-title pt-4 pr-4 ">
                        <label class="clide-v2-fs-4 ">{t('addwitness')}</label>
                      </div>
           
              {isInprogress && isApproveEnabled ? (
                <button
                  className="clide-v2-icon-btn "
                  onClick={() =>
                    functionContent.debounceFunc(() => {
                      functionContent.manageDataGatheringParams("witness", {
                        isAddEnable: true,
                      });
                    })
                  }
                >
                  <PlusIconRed />
                </button>
              ) : null}
            </div>
          </div>
          {witness?.isAddEnable ||
          !_.isEmpty(iraDetailStep3?.witness_details?.witness) ? (
            <>
              {witness?.isAddEnable ? (
                <div className="border p-2 rounded row w-100">
                  <div className="col-12 col-md-10 col-lg-10">
                    <div className="row">
                      <div className="col-12 col-lg-3 col-md-3">
                        <fieldset>
                          <div className="d-flex">
                            <legend className="slds-form-element__legend slds-form-element__label">
                              {t('addPeople')}
                            </legend>
                            <div>
                              <Tooltip
                                id="help"
                                align="top left"
                                content={"Capture multiple witnesses statement"}
                                variant="learnMore"
                              >
                                <button
                                  className="slds-button slds-button_icon"
                                  aria-describedby="help"
                                >
                                  {" "}
                                  <TooltipInfoIcon
                                    data-tip
                                    data-for={`startDate`}
                                  />
                                </button>
                              </Tooltip>
                            </div>
                          </div>

                          <div className="slds-form-element__control">
                            <div className="slds-checkbox">
                              <input
                                type="checkbox"
                                name="options"
                                id="checkbox-unique-id-163"
                                checked={
                                  witness?.employee_contractor_status === 1
                                }
                                onChange={() =>
                                  functionContent.manageDataGatheringParams(
                                    "witness",
                                    {
                                      employee_contractor_status: 1,
                                    }
                                  )
                                }
                              />
                              <label
                                className="slds-checkbox__label"
                                for="checkbox-unique-id-163"
                              >
                                <span className="slds-checkbox_faux"></span>
                                <span className="slds-form-element__label ml-2 ">
                                  {t('employee')}
                                </span>
                              </label>
                            </div>
                            <div className="slds-checkbox">
                              <input
                                type="checkbox"
                                name="options"
                                id="checkbox-unique-id-164"
                                checked={
                                  witness?.employee_contractor_status === 2
                                }
                                onChange={() =>
                                  functionContent.manageDataGatheringParams(
                                    "witness",
                                    {
                                      employee_contractor_status: 2,
                                      contractor_company:
                                        witness.isEdit !== ""
                                          ? witness?.contractor_company
                                          : "",
                                    }
                                  )
                                }
                              />
                              <label
                                className="slds-checkbox__label"
                                for="checkbox-unique-id-164"
                              >
                                <span className="slds-checkbox_faux"></span>
                                <span className="slds-form-element__label ml-2 ">
                                  {t('contractor')}
                                </span>
                              </label>
                            </div>
                          </div>
                        </fieldset>
                      </div>
                      {witness?.employee_contractor_status === 2 ? (
                        <div className="col-12 col-lg-3 col-md-3">
                          <div className="slds-form-element ">
                            <label
                              className="slds-form-element__label slds-m-bottom_xx-small "
                              for="form-element-05  "
                            >
                              <abbr className="slds-required" title="required">
                                *{" "}
                              </abbr>
                              {t('contractorCompany')}
                            </label>
                            <div className="slds-form-element__control">
                              <input
                                type="text"
                                id="form-element-05"
                                className="slds-input"
                                value={witness?.contractor_company}
                                onChange={(e) =>
                                  functionContent.manageDataGatheringParams(
                                    "witness",
                                    {
                                      contractor_company: e.target.value,
                                    }
                                  )
                                }
                                placeholder={t('enterContractorCompany')}
                              />
                            </div>
                          </div>
                        </div>
                      ) : null}
                      <div className="col-12 col-lg-3 col-md-3">
                        <div className="slds-form-element ">
                          <label
                            className="slds-form-element__label slds-m-bottom_xx-small "
                            for="form-element-05  "
                          >
                            <abbr className="slds-required" title="required">
                              *{" "}
                            </abbr>
                            {t('witnessName')}
                          </label>
                          <div className="slds-form-element__control">
                            <input
                              type="text"
                              id="form-element-05"
                              className="slds-input"
                              value={witness?.witness_name}
                              onChange={(e) =>
                                functionContent.manageDataGatheringParams(
                                  "witness",
                                  {
                                    witness_name: e.target.value,
                                  }
                                )
                              }
                              placeholder={t('enterWitnessName')}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-lg-3 col-md-3">
                        <div class="slds-form-element ">
                          <span
                            class="slds-form-element__label"
                            id="file-selector-primary-label-101"
                          >
                            {t('witnessImage')}
                          </span>
                          <div class="slds-form-element__control">
                            <div class="slds-file-selector slds-file-selector_files">
                              <div class="slds-file-selector__dropzone">
                                <input
                                  type="file"
                                  class="slds-file-selector__input slds-assistive-text"
                                  accept="image/png"
                                  id="file-upload-input-103"
                                  onChange={(e) =>
                                    functionContent._pickCropImage(e)
                                  }
                                  aria-labelledby="file-selector-primary-label-101 file-selector-secondary-label102"
                                />
                                <label
                                  class="slds-file-selector__body"
                                  for="file-upload-input-103"
                                  id="file-selector-secondary-label102"
                                >
                                  <Upload className="cursor-p mx-3" />
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {witness.files?.length ? (
                        <div className="col-12 px-0">
                          <Scrollbars
                            style={{ height: "16rem" }}
                            renderView={(props) => (
                              <div
                                style={{
                                  paddingRight: "15px",
                                  paddingBottom: "1rem",
                                  ...props.style,
                                  overflowX: "hidden",
                                }}
                              />
                            )}
                          >
                            <div className="m-3 mx-0">
                              <Files id="files-loading-example">
                                {witness.files?.map((el, idx) => {
                                  if (typeof el === "string") {
                                    return (
                                      <File
                                        id={`${idx} - demo-id`}
                                        labels={{
                                          title: "Uploaded File",
                                        }}
                                        image={el}
                                        crop="1-by-1"
                                      />
                                    );
                                  }
                                  return (
                                    <File
                                      id={`${idx} - ${el.file.name}`}
                                      key={idx}
                                      labels={{
                                        title: el.file.name,
                                      }}
                                      image={URL.createObjectURL(el.file)}
                                      crop="1-by-1"
                                    />
                                  );
                                })}
                              </Files>
                            </div>
                          </Scrollbars>
                        </div>
                      ) : null}
                      <div className="col-12 col-md-6 col-lg-6">
                        <div className="slds-form-element ">
                          <label
                            className="slds-form-element__label slds-m-bottom_xx-small "
                            for="form-element-05  "
                          >
                            <abbr className="slds-required" title="required">
                              *{" "}
                            </abbr>
                           {t('witnessStatement')}
                          </label>
                          <div className="slds-form-element__control">
                            <input
                              type="text"
                              id="form-element-05"
                              className="slds-input"
                              value={witness?.witness_statement}
                              onChange={(e) =>
                                functionContent.manageDataGatheringParams(
                                  "witness",
                                  {
                                    witness_statement: e.target.value,
                                  }
                                )
                              }
                              placeholder={t('enterWitnessStatement')}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-2 col-lg-2 ">
                    {witness?.isAddEnable ? (
                      <div>
                        {witness?.isLoading && witness?.isAddEnable ? (
                          <div className=" mx-3 mt-3">
                            <ClideLoader />{" "}
                          </div>
                        ) : witness?.isAddEnable ? (
                          <>
                            <div className="d-flex space-between justify-content-end mt-2">
                              <button
                                className="cursor-p  clide-v2-icon-btn"
                                onClick={() =>
                                  functionContent.debounceFunc(() => {
                                    functionContent.addpeoples();
                                  })
                                }
                              >
                                <RoundSave className="cursor-p " />
                              </button>
                              <button
                                className="cursor-p mx-2 clide-v2-icon-btn"
                                onClick={() =>
                                  functionContent.debounceFunc(() => {
                                    functionContent.manageDataGatheringParams(
                                      "",
                                      {},
                                      true
                                    );
                                  })
                                }
                              >
                                <CrossRound />
                              </button>
                            </div>
                          </>
                        ) : null}
                      </div>
                    ) : null}
                  </div>
                </div>
              ) : null}
            </>
          ) : null}

          {!witness?.isAddEnable &&
          !_.isEmpty(iraDetailStep3?.witness_details?.witness) ? (
            <div>
              {state.iraDetailStep3?.witness_details?.witness?.map(
                (el, idx) => (
                  <div className="border p-2 rounded row w-100 mb-3" key={idx}>
                    <div className="row">
                      <div className="col-12 col-md-10 col-lg-10">
                        <div className="row">
                          <div class="col-12 px-0">
                            <div className="row">
                              <div class="col-6  px-0">
                                <div class="clide-v2-title mb-1">
                                  <label class="clide-v2-fs-4 mb-0">
                                    {t('witnessPerson')}{" "}
                                  </label>
                                </div>
                              </div>

                              <div class="col-6 d-flex justify-content-end px-0">
                                <div class="badge border clide-v2-title py-2">
                                  <label class="mb-0">{el?.witness_name}</label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <hr />
                        <div className="row">
                          <div class="col-12 col-lg-4 col-md-4">
                            
                            <div class="clide-v2-title mb-">
                              <div className="carousel-slds-height">
                                {el?.witness_pic.length ? (
                                  <Carousel
                                    id="carousel-three-items-example"
                                    items={functionContent.getCarouselProps(
                                      el?.witness_pic,
                                      (sub) => {
                                        return {
                                          id: sub?.pic_id,
                                          src: sub?.witness_pic,
                                        };
                                      }
                                    )}
                                    itemsPerPanel={1}
                                    onItemClick={(item) => {}}
                                  />
                                ) : (
                                  <span className="font-14 mt-5">
                                    {t('noEvidence')}{" "}
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                          <div class="col-12 col-lg-8 col-md-8">
                            <div className="row">
                              <div class="col-12 col-lg-4 col-md-4">
                                <div class="col-12 col-md-12 col-lg-12 px-0">
                                  <div class="clide-v2-title mb-1">
                                    <label class="clide-v2-fs-4 mb-0">
                                      {" "}
                                      {t('type')}{" "}
                                    </label>
                                  </div>
                                </div>
                                <div class="clide-v2-title mb-">
                                  <label class="">{el?.employee_type}</label>
                                </div>
                              </div>

                              {el.employee_contractor_status === 2 ? (
                                <div class="col-12 col-lg-8 col-md-8">
                                  <div class="col-12 col-md-12 col-lg-12 px-0">
                                    <div class="clide-v2-title mb-1">
                                      <label class="clide-v2-fs-4 mb-0">
                                        {" "}
                                        {t('contractorCompanyFUC')}{" "}
                                      </label>
                                    </div>
                                  </div>
                                  <div class="clide-v2-title mb-">
                                    <label class="">
                                      {el?.contractor_company}
                                    </label>
                                  </div>
                                </div>
                              ) : null}
                              <div class="col-12 col-lg-12 col-md-12">
                                <div class="col-12 col-md-12 col-lg-12 px-0">
                                  <div class="clide-v2-title mb-1">
                                    <label class="clide-v2-fs-4 mb-0 mr-2">
                                      {" "}
                                      {t('witnessStatement')}{" "}
                                    </label>
                                  </div>
                                </div>
                                <div class="clide-v2-title mb-">
                                  <label class="">
                                    {el?.witness_statement}
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-lg-2 col-md-2">
                        <div className="d-flex space-between mt-2 justify-content-end">
                          {isInprogress && isApproveEnabled ? (
                            <div>
                              <button
                                onClick={() =>
                                  functionContent.debounceFunc(() => {
                                    functionContent.manageWitnessPayload(el);
                                  })
                                }
                                className="clide-v2-icon-btn mr-2"
                              >
                                <RoundEdit />
                              </button>
                            </div>
                          ) : null}
                          {isInprogress && isApproveEnabled ? (
                            <div>
                              <button
                                onClick={() =>
                                  functionContent.debounceFunc(() => {
                                    functionContent.manageDataGatheringParams(
                                      "",
                                      {
                                        isDeleteEnable: true,
                                        parameters: {
                                          witness_id: el?.witness_id,
                                        },
                                        url: "/listira/deletewitnessdetails",
                                      },
                                      false,
                                      "deleteParams"
                                    );
                                  })
                                }
                                className="clide-v2-icon-btn mr-2"
                              >
                                <RoundDelete />
                              </button>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                )
              )}
            </div>
          ) : null}
        </div>
      </div>
      {/* Upload Section paper Physcial etc */}

      {/* Post Forms to add */}
      {posts?.isAddEnable ? (
        <div className="px-2">
          <div>
            <div class="d-flex">
              <div class="clide-v2-title mb-1 d-flex">
                <label class="clide-v2-fs-4 mb-1">
                  {posts?.savedPalyoad?.length ? t('edit') : t('add')}{" "}
                  {capitalizeFirstLetter(posts?.module)} {t('info')}
                </label>
                <div>
                  <Tooltip
                    id="help"
                    align="top left"
                    content={postTooltip[posts?.module]}
                    variant="learnMore"
                  >
                    <button
                      className="slds-button slds-button_icon"
                      aria-describedby="help"
                    >
                      {" "}
                      <TooltipInfoIcon />
                    </button>
                  </Tooltip>
                </div>
              </div>
            </div>
          </div>
          <div className="row border p-2 rounded  w-100">
            <div className="col-12 col-md-10 col-lg-10">
              <div className="row ">
                <div className="col-12 col-md-3 col-lg-3">
                  <div>
                    <div className="d-flex space-between">
                      <span
                        className="slds-form-element__label"
                        id="file-selector-primary-label-101"
                      >
                        {t('addImages')}
                      </span>
                      <div>
                        <Tooltip
                          id="help"
                          align="top left"
                          content={t('maxImageUpload')}
                          variant="learnMore"
                        >
                          <button
                            className="slds-button slds-button_icon "
                            aria-describedby="help"
                          >
                            {" "}
                            <TooltipInfoIcon />
                          </button>
                        </Tooltip>
                      </div>
                    </div>
                    <div class="slds-form-element__control">
                      <div class="slds-file-selector slds-file-selector_files">
                        <div class="slds-file-selector__dropzone">
                          <input
                            type="file"
                            disabled={
                              posts?.isAddEnable && posts?.files.length > 0
                            }
                            class="slds-file-selector__input slds-assistive-text"
                            accept="image/png"
                            id="file-upload-input-103"
                            onChange={(e) =>
                              functionContent._pickCropImage(e, "posts")
                            }
                            aria-labelledby="file-selector-primary-label-101 file-selector-secondary-label102"
                          />
                          <label
                            class="slds-file-selector__body"
                            for="file-upload-input-103"
                            id="file-selector-secondary-label102"
                          >
                            <Upload className="cursor-p mx-3" />
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-4 col-lg-4">
                  <div className="slds-form-element ">
                    <label
                      className="slds-form-element__label slds-m-bottom_xx-small "
                      for="form-element-05  "
                    >
                      <abbr className="slds-required" title="required">
                        *{" "}
                      </abbr>
                      {t('notes')}
                    </label>
                    <div className="slds-form-element__control">
                      <input
                        type="text"
                        id="form-element-05"
                        value={posts.file_description}
                        className="slds-input"
                        onChange={(e) =>
                          functionContent.manageDataGatheringParams("posts", {
                            file_description: e.target.value,
                          })
                        }
                        placeholder={t('addNotes')}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-12 ">
                  {postsUpload?.length ? (
                    <Scrollbars
                      style={{
                        height: "20rem",
                        width: "35rem",
                      }}
                      renderView={(props) => (
                        <div
                          style={{
                            paddingRight: "15px",
                            paddingBottom: "1rem",
                            ...props.style,
                            overflowX: "hidden",
                          }}
                        />
                      )}
                    >
                      <div className="mt-3 ">
                        <Files id="files-loading-example">
                          {postsUpload?.map((el, idx) => {
                            if (Object.hasOwn(el, "image_id")) {
                              return (
                                <File
                                  id={`${idx} - ${el.image_id}`}
                                  key={idx}
                                  labels={{
                                    title: t('uploadedFile'),
                                  }}
                                  image={el?.image_link}
                                  crop="1-by-1"
                                />
                              );
                            } else {
                              return (
                                <File
                                  id={`${idx} - ${el.file.name}`}
                                  key={idx}
                                  labels={{
                                    title: el.file.name,
                                  }}
                                  image={URL.createObjectURL(el.file)}
                                  crop="1-by-1"
                                />
                              );
                            }
                          })}
                        </Files>
                      </div>
                    </Scrollbars>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="col-12 col-md-2 col-lg-2 ">
              {posts?.isAddEnable ? (
                <div>
                  {posts?.isLoading && posts?.isAddEnable ? (
                    <div className=" mx-3 mt-3">
                      <ClideLoader />{" "}
                    </div>
                  ) : posts?.isAddEnable ? (
                    <>
                      <div className="d-flex space-between justify-content-end mt-2">
                        <button
                          className="cursor-p  clide-v2-icon-btn"
                          onClick={() =>
                            functionContent.debounceFunc(() => {
                              functionContent.insertPosts();
                            })
                          }
                        >
                          <RoundSave className="cursor-p " />
                        </button>
                        <button
                          className="cursor-p mx-2 clide-v2-icon-btn"
                          onClick={() =>
                            functionContent.debounceFunc(() => {
                              functionContent.manageDataGatheringParams(
                                "",
                                {},
                                true
                              );
                            })
                          }
                        >
                          <CrossRound />
                        </button>
                      </div>
                    </>
                  ) : null}
                </div>
              ) : null}
            </div>
          </div>
        </div>
      ) : null}

      {/* View Mode of Posts */}
      {!posts?.isAddEnable ? (
        <div className="mx-3">
          <div className="row border p-2 rounded  w-100">
            <div className="col-12 ">
              <div className="row my-3">
                <div className="col-12 col-md-6 col-lg-6">
                  <div className="row my-3">
                    <div className="col-10">
                      <div class="clide-v2-title  ">
                        <label class="clide-v2-fs-4 ">{t('paperDigital')}</label>
                      </div>
                    </div>
                    <div className="col-2 px-0">
                      {isInprogress && isApproveEnabled ? (
                        <button
                          className="clide-v2-icon-btn "
                          onClick={() =>
                            functionContent.debounceFunc(() => {
                              functionContent.manageDataGatheringParams(
                                "posts",
                                {
                                  isAddEnable: true,
                                  module: t('paper'),
                                }
                              );
                            })
                          }
                        >
                          <PlusIconRed />
                        </button>
                      ) : null}
                    </div>
                  </div>

                  <div
                    style={{
                      maxWidth: "16rem",
                      minWidth: "16rem",
                      minHeight: "16rem",
                    }}
                    className="carousel-slds-height"
                  >
                    {iraDetailStep3?.paperDigital?.images?.length ? (
                      <Carousel
                        id="carousel-three-items-example"
                        items={
                          functionContent.getCarouselProps(
                            iraDetailStep3?.paperDigital?.images,
                            (sub) => {
                              return {
                                id: sub?.image_id ?? "11",
                                src: sub?.image_link,
                                heading: (
                                  <div>
                                    {" "}
                                    <div className="my-2">
                                      <span className="">
                                        {sub?.image_description}
                                      </span>{" "}
                                    </div>
                                    <div className="ml-2 d-flex w-5vw">
                                      {isInprogress && isApproveEnabled ? (
                                        <>
                                          <div className="d-flex space-between mt-2 justify-content-end">
                                            <div>
                                              <button
                                                onClick={() =>
                                                  functionContent.debounceFunc(
                                                    () => {
                                                      functionContent.manageDataGatheringParams(
                                                        "posts",
                                                        {
                                                          isAddEnable: true,
                                                          module: t('paper'),
                                                          savedPalyoad: [sub],
                                                          file_description:
                                                            sub?.image_description,
                                                        }
                                                      );
                                                    }
                                                  )
                                                }
                                                className="clide-v2-icon-btn mr-2"
                                              >
                                                <RoundEdit />
                                              </button>
                                            </div>

                                            <div>
                                              <button
                                                onClick={() =>
                                                  functionContent.debounceFunc(
                                                    () => {
                                                      functionContent.manageDataGatheringParams(
                                                        "",
                                                        {
                                                          isDeleteEnable: true,
                                                          parameters: {
                                                            image_id:
                                                              sub?.image_id,
                                                          },
                                                          url: "/listira/deletepaperdigital",
                                                        },
                                                        false,
                                                        "deleteParams"
                                                      );
                                                    }
                                                  )
                                                }
                                                className="clide-v2-icon-btn mr-2"
                                              >
                                                <RoundDelete />
                                              </button>
                                            </div>
                                          </div>
                                        </>
                                      ) : null}
                                    </div>{" "}
                                  </div>
                                ),
                              };
                            }
                          ) ?? []
                        }
                        onItemClick={(item) => {}}
                        itemsPerPanel={1}
                      />
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-6">
                  <div className="row my-3">
                    <div className="col-10">
                      <div class="clide-v2-title  ">
                        <label class="clide-v2-fs-4 ">{t('physcialInfo')}</label>
                      </div>
                    </div>
                    <div className="col-2">
                      {isInprogress && isApproveEnabled ? (
                        <button
                          className="clide-v2-icon-btn "
                          onClick={() =>
                            functionContent.debounceFunc(() => {
                              functionContent.manageDataGatheringParams(
                                "posts",
                                {
                                  isAddEnable: true,
                                  module: t('physcial'),
                                }
                              );
                            })
                          }
                        >
                          <PlusIconRed />
                        </button>
                      ) : null}
                    </div>
                  </div>
                  <div
                    style={{
                      maxWidth: "16rem",
                      minWidth: "16rem",
                      minHeight: "16rem",
                    }}
                    className="carousel-slds-height"
                  >
                    {iraDetailStep3?.physicalInfo?.images?.length ? (
                      <Carousel
                        id="carousel-three-items-example"
                        items={
                          functionContent.getCarouselProps(
                            iraDetailStep3?.physicalInfo?.images,
                            (sub) => {
                              return {
                                id: sub?.image_id ?? "11",
                                src: sub?.image_link,
                                heading: (
                                  <div>
                                    {" "}
                                    <div className="my-2">
                                      <span className="">
                                        {sub?.image_description}
                                      </span>{" "}
                                    </div>
                                    <div className="ml-2 d-flex w-5vw">
                                      {isInprogress && isApproveEnabled ? (
                                        <>
                                          <div className="d-flex space-between mt-2 justify-content-end">
                                            <div>
                                              <button
                                                onClick={() =>
                                                  functionContent.debounceFunc(
                                                    () => {
                                                      functionContent.manageDataGatheringParams(
                                                        "posts",
                                                        {
                                                          isAddEnable: true,
                                                          module: t('physcial'),
                                                          savedPalyoad: [sub],
                                                          file_description:
                                                            sub?.image_description,
                                                        }
                                                      );
                                                    }
                                                  )
                                                }
                                                className="clide-v2-icon-btn mr-2"
                                              >
                                                <RoundEdit />
                                              </button>
                                            </div>

                                            <div>
                                              <button
                                                onClick={() =>
                                                  functionContent.debounceFunc(
                                                    () => {
                                                      functionContent.manageDataGatheringParams(
                                                        "",
                                                        {
                                                          isDeleteEnable: true,
                                                          parameters: {
                                                            image_id:
                                                              sub?.image_id,
                                                          },
                                                          url: "/listira/deletephysicalinfo",
                                                        },
                                                        false,
                                                        "deleteParams"
                                                      );
                                                    }
                                                  )
                                                }
                                                className="clide-v2-icon-btn mr-2"
                                              >
                                                <RoundDelete />
                                              </button>
                                            </div>
                                          </div>
                                        </>
                                      ) : null}
                                    </div>{" "}
                                  </div>
                                ),
                              };
                            }
                          ) ?? []
                        }
                        onItemClick={(item) => {}}
                        itemsPerPanel={1}
                      />
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-6">
                  <div className="row my-3">
                    <div className="col-10 ">
                      <div class="clide-v2-title  ">
                        <label class="clide-v2-fs-4 ">
                          {t('positionEquipments')}
                        </label>
                      </div>
                    </div>
                    <div className="col-2 px-0">
                      {isInprogress && isApproveEnabled ? (
                        <button
                          className="clide-v2-icon-btn "
                          onClick={() =>
                            functionContent.debounceFunc(() => {
                              functionContent.manageDataGatheringParams(
                                "posts",
                                {
                                  isAddEnable: true,
                                  module: t('position'),
                                }
                              );
                            })
                          }
                        >
                          <PlusIconRed />
                        </button>
                      ) : null}
                    </div>
                  </div>
                  <div
                    style={{
                      maxWidth: "16rem",
                      minWidth: "16rem",
                      minHeight: "16rem",
                    }}
                    className="carousel-slds-height"
                  >
                    {iraDetailStep3?.positionEquipments?.images?.length ? (
                      <Carousel
                        id="carousel-three-items-example"
                        items={
                          functionContent.getCarouselProps(
                            iraDetailStep3?.positionEquipments?.images,
                            (sub) => {
                              return {
                                id: sub?.image_id ?? "11",
                                src: sub?.image_link,
                                heading: (
                                  <div>
                                    {" "}
                                    <div className="my-2">
                                      <span className="">
                                        {sub?.image_description}
                                      </span>{" "}
                                    </div>
                                    <div className="ml-2 d-flex w-5vw">
                                      {isInprogress && isApproveEnabled ? (
                                        <>
                                          <div className="d-flex space-between mt-2 justify-content-end">
                                            <div>
                                              <button
                                                onClick={() =>
                                                  functionContent.debounceFunc(
                                                    () => {
                                                      functionContent.manageDataGatheringParams(
                                                        "posts",
                                                        {
                                                          isAddEnable: true,
                                                          module: t('position'),
                                                          savedPalyoad: [sub],
                                                          file_description:
                                                            sub?.image_description,
                                                        }
                                                      );
                                                    }
                                                  )
                                                }
                                                className="clide-v2-icon-btn mr-2"
                                              >
                                                <RoundEdit />
                                              </button>
                                            </div>

                                            <div>
                                              <button
                                                onClick={() =>
                                                  functionContent.debounceFunc(
                                                    () => {
                                                      functionContent.manageDataGatheringParams(
                                                        "",
                                                        {
                                                          isDeleteEnable: true,
                                                          parameters: {
                                                            image_id:
                                                              sub?.image_id,
                                                          },
                                                          url: "/listira/deletepositionequipments",
                                                        },
                                                        false,
                                                        "deleteParams"
                                                      );
                                                    }
                                                  )
                                                }
                                                className="clide-v2-icon-btn mr-2"
                                              >
                                                <RoundDelete />
                                              </button>
                                            </div>
                                          </div>
                                        </>
                                      ) : null}
                                    </div>{" "}
                                  </div>
                                ),
                              };
                            }
                          ) ?? []
                        }
                        onItemClick={(item) => {}}
                        itemsPerPanel={1}
                      />
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-6">
                  <div className="row my-3">
                    <div className="col-10 ">
                      <div class="clide-v2-title  ">
                        <label class="clide-v2-fs-4 ">
                        {t('sketch')}
                        </label>
                      </div>
                    </div>
                    <div className="col-2 px-0">
                      {isInprogress && isApproveEnabled ? (
                        <button
                          className="clide-v2-icon-btn "
                          onClick={() =>
                            functionContent.debounceFunc(() => {
                              functionContent.manageDataGatheringParams(
                                "posts",
                                {
                                  isAddEnable: true,
                                  module: t('sketch'),
                                }
                              );
                            })
                          }
                        >
                          <PlusIconRed />
                        </button>
                      ) : null}
                    </div>
                  </div>
                  <div
                    style={{
                      maxWidth: "16rem",
                      minWidth: "16rem",
                      minHeight: "16rem",
                    }}
                    className="carousel-slds-height"
                  >
                    {iraDetailStep3?.sketch?.images?.length ? (
                      <Carousel
                        id="carousel-three-items-example"
                        items={
                          functionContent.getCarouselProps(
                            iraDetailStep3?.sketch?.images,
                            (sub) => {
                              return {
                                id: sub?.image_id ?? "11",
                                src: sub?.image_link,
                                heading: (
                                  <div>
                                    {" "}
                                    <div className="my-2">
                                      <span className="">
                                        {sub?.image_description}
                                      </span>{" "}
                                    </div>
                                    <div className="ml-2 d-flex w-5vw">
                                      {isInprogress && isApproveEnabled ? (
                                        <>
                                          <div className="d-flex space-between mt-2 justify-content-end">
                                            <div>
                                              <button
                                                onClick={() =>
                                                  functionContent.debounceFunc(
                                                    () => {
                                                      functionContent.manageDataGatheringParams(
                                                        "posts",
                                                        {
                                                          isAddEnable: true,
                                                          module: t('sketch'),
                                                          savedPalyoad: [sub],
                                                          file_description:
                                                            sub?.image_description,
                                                        }
                                                      );
                                                    }
                                                  )
                                                }
                                                className="clide-v2-icon-btn mr-2"
                                              >
                                                <RoundEdit />
                                              </button>
                                            </div>

                                            <div>
                                              <button
                                                onClick={() =>
                                                  functionContent.debounceFunc(
                                                    () => {
                                                      functionContent.manageDataGatheringParams(
                                                        "",
                                                        {
                                                          isDeleteEnable: true,
                                                          parameters: {
                                                            image_id:
                                                              sub?.image_id,
                                                          },
                                                          url: "/listira/deletesketch",
                                                        },
                                                        false,
                                                        "deleteParams"
                                                      );
                                                    }
                                                  )
                                                }
                                                className="clide-v2-icon-btn mr-2"
                                              >
                                                <RoundDelete />
                                              </button>
                                            </div>
                                          </div>
                                        </>
                                      ) : null}
                                    </div>{" "}
                                  </div>
                                ),
                              };
                            }
                          ) ?? []
                        }
                        onItemClick={(item) => {}}
                        itemsPerPanel={1}
                      />
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}

      {/* Mandays Costs */}
      <div className="row mt-3">
        <div className="col-12 col-md-12 col-lg-12">
          <div className="row">
            <div className="col-12 col-lg-10 col-md-10 px0">
              <div className="clide-v2-title ">
                <label className="clide-v2-fs-4 "> {t('mandaysCost')}</label>
              </div>
              <hr />
            </div>
            <div className="col-12 col-lg-2 col-md-2 px-0">
              <div className="d-flex justify-content-end">
                {!costList?.length &&
                (isInprogress || isCompleted) &&
                isApproveEnabled &&
                !cost?.isAddEnable ? (
                  <button
                    className="clide-v2-icon-btn "
                    onClick={() =>
                      functionContent.debounceFunc(() => {
                        functionContent.manageDataGatheringParams("cost", {
                          isAddEnable: true,
                        });
                      })
                    }
                  >
                    <PlusIconRed />
                  </button>
                ) : null}
                {costList?.length > 0 &&
                (isInprogress || isCompleted) &&
                isApproveEnabled &&
                !cost?.isAddEnable ? (
                  <div>
                    <button
                      onClick={() =>
                        functionContent.debounceFunc(() => {
                          functionContent.manageCostPayload(costList[0]);
                        })
                      }
                      className="clide-v2-icon-btn mr-2"
                    >
                      <RoundEdit />
                    </button>
                  </div>
                ) : null}
                {costList?.length &&
                isInprogress &&
                isApproveEnabled &&
                !cost?.isAddEnable ? (
                  <div>
                    <button
                      onClick={() =>
                        functionContent.debounceFunc(() => {
                          functionContent.manageDataGatheringParams(
                            "",
                            {
                              isDeleteEnable: true,
                              parameters: {
                                ira_cost_detail_id:
                                  costList[0]?.ira_cost_involved_id,
                              },
                              url: "/listira/step3deletecost",
                            },
                            false,
                            "deleteParams"
                          );
                        })
                      }
                      className="clide-v2-icon-btn mr-2"
                    >
                      <RoundDelete />
                    </button>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
      {cost?.isAddEnable ? (
        <div className="border p-2 rounded mx-4  ">
          <div className="row ">
            <div className="col-12 col-md-10 col-lg-10">
              <div className="row ">
                <div className="col-md-4 col-lg-4 col-12 ">
                  <Input
                    label={t('mandaysLost')}
                    placeholder={t('enterMandays')}
                    value={cost?.mandays_lost}
                    disabled={costList?.length && isCompleted}
                    onChange={(e) =>
                      functionContent.manageDataGatheringParams("cost", {
                        mandays_lost: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="col-md-4 col-lg-4 col-12 ">
                  <Input
                    label={t('costInvolved')}
                    placeholder={t('enterRupees')}
                    value={cost?.cost_involved}
                    disabled={costList?.length && isCompleted}
                    onChange={(e) =>
                      functionContent.manageDataGatheringParams("cost", {
                        cost_involved: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="col-md-4 col-lg-4 col-12 ">
                  <Combobox
                    id="combobox-readonly-single"
                    events={{
                      onSelect: (event, { selection }) => {
                        functionContent.manageDataGatheringParams("cost", {
                          return_to_work_id: selection,
                        });
                      },
                    }}
                    labels={{
                      label: t('returnedToWork'),
                      placeholder: t('selectOpt'),
                    }}
                    options={state.returnToWorkList}
                    selection={cost.return_to_work_id}
                    variant="readonly"
                  />
                </div>
                <div className=" col-12 ">
                  <div>
                    {" "}
                    <span
                      className="slds-form-element__label"
                      id="file-selector-primary-label-101"
                    >
                      {t('uploadFiles')}
                    </span>
                    <Tooltip
                      id="help"
                      align="top left"
                      content={"Max file upload 1"}
                      variant="learnMore"
                    >
                      <button
                        className="slds-button slds-button_icon"
                        aria-describedby="help"
                      >
                        {" "}
                        <TooltipInfoIcon data-tip data-for={`startDate`} />
                      </button>
                    </Tooltip>
                  </div>
                  <MyDropzone
                    type={"9"}
                    uploadFile={(file) => {
                      functionContent.manageDataGatheringParams("cost", {
                        files: file,
                      });
                    }}
                  />
                </div>
                <div className=" col-12 col-lg-6 col-md-6 mt-3">
                  {costList?.length > 0 && costList[0]?.file_nmae ? (
                    <div>
                      <div class="slds-file slds-file_card slds-has-title">
                        <figure>
                          <a class="slds-file__crop">
                            <span class="slds-assistive-text">Preview:</span>
                            <img
                              src={
                                functionContent.getFileName(
                                  costList[0]?.file_nmae,
                                  2
                                ) !== "pdf"
                                  ? costList[0]?.file_nmae
                                  : require("assets/sls-icons/pdfIcon.svg")
                              }
                              alt="123Description of the image"
                            />
                          </a>
                          <figcaption class="slds-file__title slds-file__title_card">
                            <div class="slds-media slds-media_small slds-media_center">
                              <div class="slds-media__figure slds-line-height_reset user-icon mr-1">
                                <span class="slds-icon_container" title="pdf">
                                  <img
                                    className="clide-v2-profile-img"
                                    src={require(functionContent.getFileName(
                                      costList[0]?.file_nmae,
                                      2
                                    ) !== "pdf"
                                      ? "assets/sls-icons/imageIcon.svg"
                                      : "assets/sls-icons/pdfIcon.svg")}
                                  />
                                </span>
                              </div>
                              <div class="slds-media__body d-flex">
                                <span
                                  class="slds-file__text slds-truncate"
                                  title="File"
                                >
                                  {functionContent.getFileName(
                                    costList[0]?.file_nmae
                                  )}
                                </span>
                                <Eye
                                  className="cursor-p ml-1"
                                  onClick={() =>
                                    functionContent.debounceFunc(() => {
                                      functionContent.setState({
                                        docUrl: costList[0]?.file_nmae,
                                      });
                                    })
                                  }
                                />
                              </div>
                            </div>
                          </figcaption>
                        </figure>
                      </div>
                    </div>
                  ) : null}
                </div>
                {/* <div className=" col-12 ">
                  <Scrollbars
                    style={{
                      minHeight: "10rem",
                      maxHeight: "12rem",
                      width: "w-5rem",
                    }}
                    renderView={(props) => (
                      <div
                        style={{
                          paddingRight: "15px",
                          paddingBottom: "1rem",
                          ...props.style,
                          overflowX: "hidden",
                        }}
                      />
                    )}
                  >
                    <div className="m-3">
                      <Files id="files-loading-example">
                        {cost?.files?.map((el, idx) => (
                          <File
                            id={`${idx} - ${el.file.name}`}
                            key={idx}
                            labels={{
                              title: el.file.name,
                            }}
                            image={URL.createObjectURL(el.file)}
                            crop="1-by-1"
                          />
                        ))}
                      </Files>
                    </div>
                  </Scrollbars>
                </div> */}
              </div>
            </div>
            <div className="col-12 col-md-2 col-lg-2">
              {cost?.isAddEnable ? (
                <div className="mb-2">
                  {cost?.isLoading && cost?.isAddEnable ? (
                    <div className=" mx-3 mt-3">
                      <ClideLoader />{" "}
                    </div>
                  ) : cost?.isAddEnable ? (
                    <>
                      <div className="d-flex space-between justify-content-end mt-2">
                        <button
                          className="cursor-p  clide-v2-icon-btn"
                          onClick={() =>
                            functionContent.debounceFunc(() => {
                              functionContent.insertCost();
                            })
                          }
                        >
                          <RoundSave className="cursor-p " />
                        </button>
                        <button
                          className="cursor-p mx-2 clide-v2-icon-btn"
                          onClick={() =>
                            functionContent.debounceFunc(() => {
                              functionContent.manageDataGatheringParams(
                                "",
                                {},
                                true
                              );
                            })
                          }
                        >
                          <CrossRound />
                        </button>
                      </div>
                    </>
                  ) : null}
                </div>
              ) : null}
            </div>
          </div>
        </div>
      ) : costList?.length ? (
        <div className="border p-2 rounded mx-4 ">
          <div className="row ">
            <div className="col-12 col-md-10 col-lg-10">
              <div className="row ">
                <div className="col-md-4 col-lg-4 col-12 ">
                  <div class="col-12 col-md-12 col-lg-12 px-0">
                    <div class="clide-v2-title mb-1">
                      <label class="clide-v2-fs-4 mb-0"> {t('mandaysLost')} </label>
                    </div>
                  </div>
                  <div class="clide-v2-title mb-">
                    <label class="">{costList[0]?.mandays_lost}</label>
                  </div>
                </div>
                <div className="col-md-4 col-lg-4 col-12 ">
                  <div class="col-12 col-md-12 col-lg-12 px-0">
                    <div class="clide-v2-title mb-1">
                      <label class="clide-v2-fs-4 mb-0">
                        {" "}
                        {t('costInvolved')}{" "}
                      </label>
                    </div>
                  </div>
                  <div class="clide-v2-title mb-">
                    <label class="">{costList[0]?.cost_involved}</label>
                  </div>
                </div>
                <div className="col-md-4 col-lg-4 col-12 ">
                  <div class="col-12 col-md-12 col-lg-12 px-0">
                    <div class="clide-v2-title mb-1">
                      <label class="clide-v2-fs-4 mb-0">
                        {t('returnedToWork')}{" "}
                      </label>
                    </div>
                  </div>
                  <div class="clide-v2-title mb-">
                    <label class="">
                      {costList[0]?.return_to_work_list.map(
                        (el) => el?.return_to_work_info
                      )}
                    </label>
                  </div>
                </div>
                <div className=" col-12 ">
                  <div class="col-12 col-md-12 col-lg-12 px-0">
                    <div class="clide-v2-title mb-1">
                      <label class="clide-v2-fs-4 mb-0">{t('uploadedFile')} </label>
                    </div>
                  </div>
                  <div>
                    <div style={{ width: "13rem" }}>
                      <div class="slds-file slds-file_card slds-has-title">
                        <figure>
                          <a class="slds-file__crop">
                            <span class="slds-assistive-text">Preview:</span>
                            <img
                              src={
                                functionContent.getFileName(
                                  costList[0]?.file_nmae,
                                  2
                                ) !== "pdf"
                                  ? costList[0]?.file_nmae
                                  : require("assets/sls-icons/pdfIcon.svg")
                              }
                              alt={t('descriptionOfTheImage')}
                            />
                          </a>
                          <figcaption class="slds-file__title slds-file__title_card">
                            <div class="slds-media slds-media_small slds-media_center">
                              <div class="slds-media__figure slds-line-height_reset user-icon mr-1">
                                <span class="slds-icon_container" title="pdf">
                                  <img
                                    className="clide-v2-profile-img"
                                    src={require(functionContent.getFileName(
                                      costList[0]?.file_nmae,
                                      2
                                    ) !== "pdf"
                                      ? "assets/sls-icons/imageIcon.svg"
                                      : "assets/sls-icons/pdfIcon.svg")}
                                  />
                                </span>
                              </div>
                              <div class="slds-media__body d-flex">
                                <span
                                  class="slds-file__text slds-truncate"
                                  title="File"
                                >
                                  {functionContent.getFileName(
                                    costList[0]?.file_nmae
                                  )}
                                </span>
                                <Eye
                                  className="cursor-p ml-1"
                                  onClick={() =>
                                    functionContent.debounceFunc(() => {
                                      functionContent.setState({
                                        docUrl: costList[0]?.file_nmae,
                                      });
                                    })
                                  }
                                />
                              </div>
                            </div>
                          </figcaption>
                        </figure>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-2 col-lg-2">
              {cost?.isAddEnable ? (
                <div>
                  {cost?.isLoading && cost?.isAddEnable ? (
                    <div className=" mx-3 mt-3">
                      <ClideLoader />{" "}
                    </div>
                  ) : cost?.isAddEnable ? (
                    <>
                      <div className="d-flex space-between justify-content-end mt-2">
                        <button
                          className="cursor-p  clide-v2-icon-btn"
                          onClick={() =>
                            functionContent.debounceFunc(() => {
                              functionContent.insertCost();
                            })
                          }
                        >
                          <RoundSave className="cursor-p " />
                        </button>
                        <button
                          className="cursor-p mx-2 clide-v2-icon-btn"
                          onClick={() =>
                            functionContent.debounceFunc(() => {
                              functionContent.manageDataGatheringParams(
                                "",
                                {},
                                true
                              );
                            })
                          }
                        >
                          <CrossRound />
                        </button>
                      </div>
                    </>
                  ) : null}
                </div>
              ) : null}
            </div>
          </div>
        </div>
      ) : null}

      {/* 5M Analysis */}

      <div className="row mt-3">
        <div className="col-12 col-md-12 col-lg-12">
          <div className="row">
            <div className="col-12 col-lg-10 col-md-10">
              <div className="clide-v2-title ">
                <label className="clide-v2-fs-4 ">{t('5mAnalysis')}</label>
              </div>
            </div>
          </div>
          <div className=" fishbon-chart-wrapper mx-3 my-3 border rounded p-2">
            <FishboneChart
              data={functionContent.manageFishBoneChart(
                state.fishData,
                iraDetailStep3?.ira_5M_details,
                isInprogress && isApproveEnabled
              )}
              ref={functionContent.selectorRef}
            />
          </div>
        </div>
      </div>

      {/* Job Analysis Question */}

      <div className="row mt-3">
        <div className="col-12 col-md-12 col-lg-12">
          <div className="row">
            <div className="col-12 col-lg-10 col-md-10">
              <div className="clide-v2-title ">
                <label className="clide-v2-fs-4 "> {t('jobAnalysisQuestion')}</label>
              </div>
            </div>
            <div className="col-12 col-lg-2 col-md-2">
              <div className="d-flex justify-content-end">
                {state.reportStatus === "progress" &&
                !iraDetailStep3?.other_info?.question_response?.length &&
                isApproveEnabled ? (
                  <button
                    className="clide-v2-icon-btn "
                    onClick={() =>
                      functionContent.debounceFunc(() => {
                        functionContent.manageDataGatheringParams("analysis", {
                          isAddEnable: true,
                          module: t('paper'),
                        });
                      })
                    }
                  >
                    <PlusIconRed />
                  </button>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
      {analysis?.isAddEnable ? (
        <>
          <div>
            <div className="border rounded p-3  space-between mt-3 row">
              <div className="col-12 col-md-10 col-lg-10">
                <Scrollbars
                  style={{ height: "12rem" }}
                  renderView={(props) => (
                    <div
                      style={{
                        paddingRight: "15px",
                        paddingBottom: "1rem",
                        ...props.style,
                        overflowX: "hidden",
                      }}
                    />
                  )}
                >
                  {state.OtherInfomasterlist?.map((data, index) => (
                    <div key={index} className="row mr-3 my-3">
                      <div className="col-12 col-md-4 col-lg-4">
                        <span className="slds-form-element__label font-16 font-weight-500">
                          {data.questions}
                        </span>
                      </div>
                      <div className="col-12 col-md-4 col-lg-4">
                        <Checkbox
                          checked={data.response == 1}
                          labels={{
                            label: "Yes",
                          }}
                          value={"Yes"}
                          onChange={() => {
                            functionContent.setState({
                              OtherInfomasterlist:
                                state.OtherInfomasterlist.map((item, i) =>
                                  index === i
                                    ? {
                                        ...item,
                                        response: 1,
                                      }
                                    : item
                                ),
                            });
                          }}
                        />
                        <Checkbox
                          checked={data.response == 0}
                          labels={{
                            label: "No",
                          }}
                          value={"No"}
                          onChange={() => {
                            functionContent.setState({
                              OtherInfomasterlist:
                                state.OtherInfomasterlist.map((item, i) =>
                                  index === i
                                    ? {
                                        ...item,
                                        response: 0,
                                      }
                                    : item
                                ),
                            });
                          }}
                        />
                      </div>
                      <div className="col-12 col-md-4 col-lg-4">
                        <Input
                          label={"Add Notes"}
                          placeholder={t('enterNotes')}
                          value={data.notes}
                          className="w-75"
                          onChange={(e) => {
                            functionContent.setState({
                              OtherInfomasterlist:
                                state.OtherInfomasterlist.map((item, i) =>
                                  index === i
                                    ? {
                                        ...item,
                                        notes: e.target.value,
                                      }
                                    : item
                                ),
                            });
                          }}
                        />
                      </div>
                    </div>
                  ))}
                </Scrollbars>
              </div>
              <div className="col-12 col-md-2 col-lg-2 mb-2">
                <div className="d-flex space-between">
                  {analysis?.isLoading ? (
                    <div className=" mx-3 mt-3">
                      <ClideLoader />{" "}
                    </div>
                  ) : (
                    <>
                      <div className="d-flex space-between justify-content-end mt-2">
                        <button
                          className="cursor-p  clide-v2-icon-btn"
                          onClick={() =>
                            functionContent.debounceFunc(() => {
                              functionContent.addOtherInfo();
                            })
                          }
                        >
                          <RoundSave className="cursor-p " />
                        </button>
                        <button
                          className="cursor-p mx-2 clide-v2-icon-btn"
                          onClick={() =>
                            functionContent.debounceFunc(() => {
                              functionContent.manageDataGatheringParams(
                                "",
                                {},
                                true
                              );
                            })
                          }
                        >
                          <CrossRound />
                        </button>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}

      {(isInprogress || isCompleted) && !analysis?.isAddEnable ? (
        <>
          <Scrollbars
            style={{ height: "16rem" }}
            renderView={(props) => (
              <div
                style={{
                  paddingRight: "15px",
                  paddingBottom: "1rem",
                  ...props.style,
                  overflowX: "hidden",
                }}
              />
            )}
          >
            {iraDetailStep3?.other_info?.question_response?.map((el, idx) => (
              <>
                {" "}
                <div
                  className=" border rounded p-2 row my-3"
                  style={{ minHeight: "auto" }}
                  key={idx}
                >
                  <div className="col-12 col-lg-2 col-md-2">
                    <span class="badge clide-v2-secondary-color bg-transparent border common-black-1 font-weight-500 font-18 px-4 py-3 rounded-pill">
                      Q {idx + 1}{" "}
                    </span>
                  </div>
                  <div className="col-12 col-md-9 col-lg-9">
                    <div className="row">
                      <div class="feild-group field d-flex common-flex-gap clide-v2-title align-items-baseline">
                        <label className="clide-v2-fs-4 ">{t('question')} :</label>
                        <div class="output-text ml-2">{el?.questions}</div>
                      </div>
                      <div class="feild-group field d-flex common-flex-gap clide-v2-title align-items-baseline">
                        <label className="clide-v2-fs-4 ">{t('option')} :</label>
                        <div class="d-flex ml-2">
                          <Checkbox
                            checked={el?.response === "Yes"}
                            labels={{
                              label: "Yes",
                            }}
                            value={"Yes"}
                          />
                          <Checkbox
                            checked={el?.response === "No"}
                            labels={{
                              label: "No",
                            }}
                            value={"No"}
                            className="ml-3"
                          />
                        </div>
                      </div>
                      <div class="feild-group field d-flex common-flex-gap clide-v2-title align-items-baseline">
                        <label className="clide-v2-fs-4 ">{t('notes')} :</label>
                        <div class="output-text ml-2">{el?.notes}</div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-1 col-lg-1 mb-2">
                    <div className="d-flex space-between">
                      {isInprogress && isApproveEnabled ? (
                        <button
                          className="cursor-p  clide-v2-icon-btn"
                          onClick={() =>
                            functionContent.debounceFunc(() => {
                              functionContent.manageJobAnalysisEdit(el);
                            })
                          }
                        >
                          <RoundEdit className="cursor-p " />
                        </button>
                      ) : null}
                    </div>
                  </div>
                </div>
              </>
            ))}
          </Scrollbars>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default DetailStep3;
