import React, { useState } from 'react';
import { Youtube } from 'react-feather';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import VideoBox from "app/shared/components/ReusableComponents/VideoPlayer/container";
import img from "assets/images/no-image2.jpg";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import AIModal from "./aiModal";
import { Modal } from "react-bootstrap";
import usePermittedRoute from "app/hook/usePermittedRoute";
import { AI } from 'app/shared/constants/ModuleNames';
import { useTranslation } from "react-i18next";

export const CarouselComponent = ({ wrapperClassName, carouselProps, items = [], isExtendView = false, displayEmptyIndicator = true }) => {

    const [aiData, setAiData] = useState({});
    const [isCommunicationNotification, setCommunicationNotification] = React.useState(false);
    const [_checkPermittedRoute] = usePermittedRoute();
    const { t } = useTranslation(['util']);
    const aiModal = (element) => {
        setAiData(element);
        setCommunicationNotification(true);
    };

    const __renderAiOverlay = (aiStatus, element) => {
        if(!_checkPermittedRoute(AI)) return;

        if (aiStatus == "1") {
            return <div onClick={() => aiModal(element)} className="ai-overlay-verify" style={{ cursor: "pointer" }}>{t('ai')} <IoMdCheckmarkCircleOutline /></div>;
        }else if(aiStatus == undefined){
            return;
        }else {
            console.log("render called");
            return <div onClick={() => aiModal(element)} className="ai-overlay-verify" style={{ cursor: "pointer" }}>{t('ai')}<IoMdCheckmarkCircleOutline /></div>;
            //  <div className="ai-overlay-unverify"
            //  onClick={() => aiModal(element)}>
            //  AI <RxCrossCircled /></div>;
        }
    }

    const __renderCarouselContent = (element) => {
        const { url, contentType, aiStatus, aiImage, aiResponse } = element ?? {};
        if (contentType === "image") {
            return <>
                <img
                    src={url}
                    // style={{ maxHeight: "15vw" }}
                    alt={t('thumbnail')}
                />
                {__renderAiOverlay(aiStatus, element)}
            </>
        } else if (contentType === "video") {
            return <VideoBox url={url} aiStatus={aiStatus} element={element} renderAiOverlay={__renderAiOverlay} />

        }

    }
    const arrowStyles = {
        position: 'absolute',
        zIndex: 2,
        top: 'calc(50% - 15px)',
        width: 30,
        height: 30,
        cursor: 'pointer',
        color: "#000"
    };

    const indicatorStyles = {
        background: "#D9D9D9",
        borderRadius: "13px",
        width: "1.5vw",
        cursor: "pointer",
        height: 8,
        display: 'inline-block',
        margin: '0 8px',
    };
    const customRenderItem = (item, props) => <item.type {...item.props} {...props} />;
    const customRenderThumb = (children) =>
        children.map((item) => {
            if (item.props.contentType === "image") {
                return <img src={item.props.itemUrl} />;
            } else if (item.props.contentType === "video") {
                return <Youtube
                    style={{
                        display: "block",
                        width: "inherit",
                        height: "inherit",
                        padding: "0px 10px",
                    }}
                />
            }

        })
    return (
        <div className={`${wrapperClassName} ${!isExtendView ? "remove-thumbnail" : ""} ${items.length === 1 ? "remove-indicator" : ""}`} >
            {items.length ? <Carousel {...carouselProps}

                renderArrowPrev={(onClickHandler, hasPrev, label) =>
                    hasPrev && (

                        // <ChevronLeft title={label} onClick={onClickHandler} style={{ ...arrowStyles, left: 15 }} />
                        <></>
                    )
                }
                renderArrowNext={(onClickHandler, hasNext, label) =>
                    hasNext && (
                        <></>
                        // <ChevronRight onClick={onClickHandler} title={label} style={{ ...arrowStyles, right: 15 }} />
                    )
                }
                renderIndicator={(onClickHandler, isSelected, index, label) => {
                    if (isSelected) {
                        return (
                            <li
                                style={{ ...indicatorStyles, background: '#000'}}
                                aria-label={`Selected: ${label} ${index + 1}`}
                                title={`Selected: ${label} ${index + 1}`}
                            />
                        );
                    }
                    return (
                        <li
                            style={indicatorStyles}
                            onClick={onClickHandler}
                            onKeyDown={onClickHandler}
                            value={index}
                            key={index}
                            role="button"
                            tabIndex={0}
                            title={`${label} ${index + 1}`}
                            aria-label={`${label} ${index + 1}`}
                        />
                    );
                }}
                renderItem={customRenderItem} renderThumbs={customRenderThumb}
            >
                {items?.map((el, idx) => <div key={idx} contentType={el.contentType} itemUrl={el.url} > {__renderCarouselContent(el)} </div>)
                }
            </Carousel> : <div >
                {displayEmptyIndicator ? <img  src={img} className="img-fluid" /> : <></>

                }

            </div>}
            <Modal
                show={isCommunicationNotification}
                onHide={() => setCommunicationNotification(false)}
                dialogclassname={aiData?.evidence?.imageUrl ? "modal-xl" : "modal"}
                closebutton
            >
                <AIModal
                    aiData={aiData}
                    handleClose={() => setCommunicationNotification(false)}
                    type={"uauc"}
                /></Modal>
        </div >
    )
}

