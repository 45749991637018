//libraires
import React from "react";
//components
import TabsV2 from "app/shared/ui-kit/tabs";
import NMDashboardV2 from "./modules/dashboard";
import CapaListNMV2 from "./modules/capa";
import NmListV2 from "./modules/list";
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
//constants
import { nmContent } from "./constants/nmConstant";
//styles
import "./styles/nmStyles.scss";

class NMModuleV2 extends React.Component {
  constructor(props) {
    super(props);
    const {t} = this.props;
    this.state = {
      current: nmContent(t).dasboardTabs[0],
      moduleActionContent:""
    };
  }

  updateActionContent = (element = "") => {
    this.setState({moduleActionContent:element})
  }
  
  render() {
    const {key = "",moduleActionContent} = this.state.current ?? {}
    const nmPending =
      this.props.state.total_pendingnm != undefined && this.props.state.total_pendingnm;
    const nmProgress =
      this.props.state.total_inprogressnm != undefined &&
      this.props.state.total_inprogressnm;
    const nmCompleted =
      this.props.state.total_completednm != undefined && this.props.state.total_completednm;
      const {t} = this.props;
    return (
      <div className=" clide-v2-colume_title">
        <div className="mb-4  clide-v2-fs-3 fw-bold">{t('nearMiss')}</div>
        <div className="mb-3">
          <div className="row">
            <div className="col-12 col-md-6 col-lg-7">
              <TabsV2
                tabs={nmContent(t).dasboardTabs}
                currentItem={this.state.current}
                handleChange={(key) => this.setState({ current: key })}
                extraValues={{
                  capa:this.props.state.totalCapaCount || 0,
                  list: nmCompleted + nmProgress + nmPending
                }}
              />
            </div>
            <div className="col-12 col-md-6 col-lg-5">
              <div className="row">
                <div className="col-12 col-md-2">
                  <div className="d-flex">
                      {moduleActionContent}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {key === "dashboard" && <NMDashboardV2 updateActionContent={this.updateActionContent} />
        }
        {key === "capa" && <CapaListNMV2 {...this.props?.capaProps} state={this.props?.state} updateActionContent={this.updateActionContent} />
        }
        {key === "list" && <NmListV2 {...this.props.listProps} updateActionContent={this.updateActionContent} />
        }
        
      </div>
    );
  }
}

export default compose(
  withTranslation("util")
)(NMModuleV2);
