/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import React,  { useState } from "react";
import { Button , Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
//assets
//assets
import {
  RoundDelete,
  RoundEdit,
  TeamAdd,PolicyIcon,DdIcon,ElearnIcon,
  PolicyIconOrange,
  DdIconOrange,
  ElearnIconOrange
} from "assets/Iconsv2/Iconsv2";
import { components } from "react-select/dist/react-select.cjs.prod";
const DetailTimelineSection = ({
  data = [],
  originFlag = "",
  state,
  variablesObj,
  functionContent,
}) => {
  const { nmDetail = {}, soiId = "" } = state ?? {};
  const { t } = useTranslation(['nm', 'util']);

  const [showModalImage, setShowModalImage] = useState(false);
  const [showModalVideo, setShowModalVideo] = useState(false);
  const [showModalPdf, setShowModalPdf] = useState(false);
  const handleShowImage = () => {setShowModalImage(true);}
  const handleCloseImage = () => setShowModalImage(false);
  const handleShowVideo = () => {setShowModalVideo(true);}
  const handleCloseVideo = () => setShowModalVideo(false);
  const handleShowPdf= () => {setShowModalPdf(true);}
  const handleClosePdf = () => setShowModalPdf(false);
  const _handleBackButtonAction = () => {
    if (originFlag == "kanban") {
      return "/nm/kanban";
    } else {
      return "/";
    }
  };
  const { isApproveEnabled } = variablesObj;
/*   useEffect(() => {
    functionContent.fetchActivityDetails()
  }, []); */
  // functionContent.fetchActivityDetails()

  return (
    <div className="row  clide-v2-colume_title ">
      <div className="mb-1 clide-v2-fs-3 fw-bold">{t('nmDetail')}</div>
      <div className="row my-4 align-items-center">
        <div className="col-12 col-lg-4 col-md-4 px-0">
          <Link to={_handleBackButtonAction()}>
            <Button
              className="border clide-v2-primary clide-v2-white-color"
              variant=""
            >
              <i className="fa fa-angle-left" aria-hidden="true"></i>
            </Button>
          </Link>
        </div>
        <div className="col-12 col-lg-4 col-md-4">
          <div>
            <span className="common-black-1 font-weight-500">
              {" "}
              {soiId ?? ""}{" "}
              <span
                style={{
                  borderLeft: "3px Solid Orange",
                  padding: "0 5px 0 5px",
                }}
              >
                {" "}
                {nmDetail.type_of_act == "condition" ?  t('uc') : t('ua')} -{" "}
                {nmDetail.activity}{" "}
              </span>
              <span
                style={{
                  borderLeft: "3px Solid Orange",
                  padding: "0 5px 0 5px",
                }}
              >
                {nmDetail?.reportedmonth_name}
              </span>
            </span>
          </div>
        </div>
        <div className="col-12 col-lg-4 col-md-4 d-flex justify-content-end px-0">
          <div className="d-flex align-items-center">

          {state?.activity_img ? (
                  <div>
                    <button className="clide-v2-icon-btn mr-2"
                      title={t('dd')}
                      onClick={handleShowImage} >
                      <DdIconOrange />
                    </button>
                    <Modal
                      show={showModalImage}
                      onHide={handleCloseImage}
                      className="reportPopup "
                    >
                      <Modal.Header closeButton>
                        <Modal.Title>{t('doDonts')}</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <img src={state.activity_img} alt="">
                        </img>
                      </Modal.Body>
                    </Modal>
                  </div>
                ) : null}

                {state?.activity_video ? (
                  <div>
                    <button className="clide-v2-icon-btn mr-2"
                      title={t('eLearn')}
                      onClick={handleShowVideo} >
                      <ElearnIconOrange />
                    </button>
                    <Modal
                      show={showModalVideo}
                      onHide={handleCloseVideo}
                      className="reportPopup "
                    >
                      <Modal.Header closeButton>
                        <Modal.Title>{t('eLearn')}</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>

                        <video
                          controls
                          width="100%"
                          style={{ borderRadius: "8px" }}
                          src={state.activity_video}
                        > </video>
                      </Modal.Body>
                    </Modal>
                  </div>
                ) : null}

                {state?.activity_pdf ? (
                  <div>
                    <button className="clide-v2-icon-btn mr-2"
                      title={t('policy')}
                      onClick={handleShowPdf} >
                      <PolicyIconOrange />
                    </button>
                    <Modal
                      show={showModalPdf}
                      onHide={handleClosePdf}
                      className="reportPopup "
                    >
                      <Modal.Header closeButton>
                        <Modal.Title>{t('policy')}</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <iframe
                          src={`${state.activity_pdf}#toolbar=0`}
                          type="application/pdf"
                          width="100%"
                          height="500px"
                          title="PDF Viewer"
                          style={{ borderRadius: "8px" }}
                        />
                    </Modal.Body>
                  </Modal>
                </div>
              ):null}
            { ["pending", "progress"].includes(state.reportStatus)? (
              <>
                {nmDetail.edit_flag == 1 && (
                  <Link
                    to={`/edit-nm-reporting-detail/${nmDetail.nm_id}/${nmDetail.service_id}/${nmDetail.project_id}/edit`}
                  >
                    <button className="clide-v2-icon-btn mr-2" title={t('edit')}>
                      <RoundEdit />
                    </button>
                  </Link>
                )}


                {nmDetail.delete_flag == 1 ? (
                  <div>
                    <button
                      className="clide-v2-icon-btn mr-2"
                      title={t('delete')}
                      onClick={() => functionContent.showModal()}
                    >
                      <RoundDelete />
                    </button>
                  </div>
                ) : null}
              </>
            ) : null}

            {state.reportStatus == "completed" ? (
              <>
              <div className="rd-btn right-block-gap ">

                

                
                <Link to={`/nm-report/${nmDetail.nm_id}`} target="_blank">
                  <img
                    src={require("assets/sls-icons/V2. New Development/PDF.svg")}
                    className="clide-v2-profile-img clide-v2-pdf-icon"
                    alt="completed pdf"
                  />
                </Link>
              </div>
              </>
            ) : null}
            <div>
              {state.resolveFlag == "resolve" ? (
                <span className="common-black-1 font-weight-500">
                  {" "}
                  {t('resolved')}{" "}
                </span>
              ) : null}

              {["pending", "progress"].includes(state.reportStatus) &&
              isApproveEnabled ? (
                <button
                  className="clide-v2-icon-btn mr-2"
                  title={t('addTeam')}
                  onClick={functionContent.openInviteUser}
                >
                  <TeamAdd />
                </button>
              ) : null}
              <div className="image-box">
                {nmDetail.invited_user?.map((image, index) => (
                  <img
                    style={{ marginRight: "0.5vw" }}
                    src={image.userpic}
                    alt=""
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="pe-3 clide-v2-p-0">
        <div className="clide-v2-card-box-1 p-3">
          <div className="text-center clide-v2-primary-color py-4">
           {t('timeline')}
          </div>
          <div className="overflow-x overflow-auto">
            <div
              className="line_box stav_projektu"
              style={{ margin: "40px 0 40px 0" }}
            >
              {data?.map((el) => {
                return (
                  <div className="text_circle done ">
                    <a href="#" className="tvar">
                      <span />
                    </a>
                    <div className="circle clide-v2-white-box ">
                      {el?.title}
                      <hr />
                      {el?.cardTitle}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(DetailTimelineSection);
