import { Button, Col, Modal, Nav, Row, Tab } from "react-bootstrap";
import { Calendar, Mic, MicOff, XCircle } from "react-feather";
import {
  INCIDENT_TYPE,
  SEVERITY,
  TYPE_ACT,
} from "app/shared/constants/GlobalArray";
import React, { Component } from "react";

import AudioPlayer from "react-h5-audio-player";
import CommonDropdown from "./CommonDropdown";
import DatePicker from "react-datepicker";
import LoadingImage from "app/shared/components/Loader/LoadingImage";
import LowSpace from "app/shared/components/Popups/LowSpace";
import MyDropzone from "app/shared/components/DocumentModal/Dropzone";
import ProjectList from "./ProjectList";
import { ReactMic } from "react-mic";
import ServiceList from "./ServiceList";
import TimePicker from "react-times";
import Url from "app/shared/constants/Url";
import AsyncSelect from 'react-select/async';
import VoiceRecorder from "app/shared/components/VoiceRecorder/VoiceRecorder";
import axios from "axios";
import moment from "moment";
import { requiredMessage } from "app/shared/constants/Messages";
import { withRouter } from "react-router-dom";
import SubmitImage from "../../../../assets/images/submit.png";
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
const ErrorMessage = <div className="invalid-feedback">Enter valid name</div>;
const ErrorBlock = ({ message }) => (
  <div className="invalid-feedback">{message || ""}</div>
);
const qs = require("qs");

const DEPT_URL = Url.departmentList;
const CONT_URL = Url.contractorList;
const ZONE_URL = Url.zoneList;
const ACT_URL = Url.activitylist;

class GPReport extends Component {
  constructor(props) {
    super(props);
    const {t} = this.props;
    this.state = {
      formHideField: this.props.hideFieldList,
      reportType: this.props.reportType,
      serviceList: [],
      projectList: [],
      departmentList: [],
      contractorList: [],
      zoneList: [],
      locationList: [],
      userData: JSON.parse(localStorage.getItem("userData")),
      typeActList: TYPE_ACT,
      typeIncidentList: INCIDENT_TYPE,
      activityList: [],
      severityList: SEVERITY,
      barrierList: [],
      hazardList: [],
      subHazardList: [],
      searchFiledUsers: [],
      categoryList: [],
      selectedCategory: null,
      categoryError: false,
      voilatorPersonList: [],
      voilatorPersonName: "",
      voilatorId: "",
      voilatorIdList: [],
      observationText: "",
      evidenceFileList: [],
      evidenceUrlList: [],
      selectedServiceId: null,
      selectedProjectId: null,
      selectedDepartmentId: null,
      selectedContractorId: null,
      selectedZoneId: null,
      selectedLocationId: null,
      selectedTypeAct: null,
      selectedActivity: null,
      selectedSeverity: null,
      selectedBarrier: null,
      selectedHazardId: null,
      selectedSubHazardId: null,
      selectedIncidentTypeId: 1,
      selectedTargetDate: new Date(),
      maxDatepickerDate: new Date(),
      controlMeasure: "",
      resolveCheckbox: false,

      selectedIncidentDate: new Date(),
      selectedIncidentTime: "10:00",
      selectedTimeMeridiem: "AM",
      personName: "",
      personNameList: [],
      lat: 0,
      long: 0,
      observationError: false,
      serviceError: false,
      projectError: false,
      projectError: false,

      zoneError: false,
      departmentError: false,
      contractorError: false,
      locationError: false,

      typeActError: false,
      activityError: false,
      severityError: false,
      barrierError: false,
      hazardError: false,
      incidentTypeError: false,
      showRecorderModal: false,
      recordAudio: false,
      controlMeasureAudio: "",
      controlMeasureURL: "",
      observationAudio: "",
      observationURL: "",
      recordingFor: "observation",

      sucesssPopup: false,
      reportNumber: null,
      lowStorageMessage: "",
      lowStorageStatus: 200,
      showLowStoragePopup: false,
      showContractorDropdown: true,
      showZoneDropdown: true,
      showLocationDropdown: true,
      selectedFile: "",
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.hideFieldList !== prevState.formHideField) {
      return nextProps.hideFieldList;
    }
    return null;
  }

  _closeSuccessPopup = async () => {
    await this.setState({ sucesssPopup: false });
    await this._clearForm();
    this.props.close();
    this.props.history.push("/");
  };

  _continueReporting = async () => {
    await this.setState({ sucesssPopup: false });
    await this._continueReportingClearForm();
    await this._fetchServiceList();
    await this._fetchCategoryList();
  };

  _getLocation = async () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.setState((prevState) => {
          let { lat, long } = prevState;
          lat = position.coords.latitude;
          long = position.coords.longitude;
          return { lat, long };
        });
      });
    }
  };

  componentDidMount = async () => {
    await this._fetchServiceList();
    await this._getLocation();
    await this._fetchCategoryList();
    this.props.setClick(this._submitGoodPractice);
  };

  _fetchOptionList = async () => {
    const tempUserId = localStorage.getItem("userId");
    const tempToken = localStorage.getItem("token");

    let requestData = {
      auth_key: tempToken,
      user_id: tempUserId,
      service_id: this.state.selectedServiceId,
      project_id: this.state.selectedProjectId,
    };

    var ContentType = {
      "Content-Type": "application/x-www-form-urlencoded",
    };

    axios
      .all([
        // Department list
        axios.post(DEPT_URL, qs.stringify(requestData), {
          headers: ContentType,
        }),
        // Contractor List
        axios.post(CONT_URL, qs.stringify(requestData), {
          headers: ContentType,
        }),
        // Zone List
        axios.post(ZONE_URL, qs.stringify(requestData), {
          headers: ContentType,
        }),
        // Activity List
        axios.post(ACT_URL, qs.stringify(requestData), {
          headers: ContentType,
        }),
      ])
      .then(
        axios.spread(async (department, contractor, zone, activity) => {
          await this.setState((prevState) => {
            let {
              contractorList,
              departmentList,
              zoneList,
              hazardList,
              activityList,
              barrierList,
              dataLoading,
            } = prevState;

            departmentList =
              department.data.status === 200 ? department.data?.data : [];
            contractorList =
              contractor.data.status === 200 ? contractor.data?.data : [];
            zoneList = zone.data.status === 200 ? zone.data?.data : [];

            activityList =
              activity.data.status === 200 ? activity.data?.data : [];

            dataLoading = false;

            return {
              contractorList,
              departmentList,
              zoneList,
              hazardList,
              activityList,
              barrierList,
              dataLoading,
            };
          });
        })
      )
      .catch((error) => {
        this._setDataLoading(false);
      });
  };

  _setDataLoading = async (status) => {
    await this.setState((prevState) => {
      let { dataLoading } = prevState;
      dataLoading = status;
      return { dataLoading };
    });
  };

  _fetchServiceList = async () => {
    import("app/shared/utility/UtilFunction").then((utilFunction) => {
      let responseObj = utilFunction.sharedAxiosAPI(Url.serviceList);

      responseObj
        .then(async (response) => {
          if (response.data.status === 200) {
            await this.setState((prevState) => {
              let { serviceList } = prevState;
              serviceList = response.data.data;
              return { serviceList };
            });
            if (this.state.userData.designation_flag === 4) {
              this.setState({
                selectedServiceId: response.data.data[0]['service_id']
              }, () => void this._fetchProjectList(response.data.data[0]['service_id']))
            }
            this._setDataLoading(false);
          }
        })
        .catch((error) => {
          this._setDataLoading(false);
        });
    });
  };

  _serviceChangeHandle = async (serviceId) => {
    await this.setState((prevState) => {
      let { selectedServiceId, serviceError } = prevState;
      selectedServiceId = serviceId;
      serviceError = serviceId ? false : true;
      return { selectedServiceId, serviceError };
    });
    await this._resetDropdwon();
    this._fetchProjectList(serviceId);
  };

  _resetDropdwon = async (projectReset = true) => {
    await this.setState((prevState) => {
      let {
        selectedHazardId,
        selectedSubHazardId,
        selectedProjectId,
        selectedContractorId,
        selectedDepartmentId,
        selectedActivity,
        selectedLocationId,
        selectedZoneId,
        selectedBarrier,
        hazardList,
        subHazardList,
        projectList,
        contractorList,
        departmentList,
        activityList,
        locationList,
        barrierList,
        zoneList,
      } = prevState;

      if (projectReset) {
        selectedProjectId = null;
        projectList = [];
      }

      selectedHazardId = null;
      selectedSubHazardId = null;
      selectedContractorId = null;
      selectedActivity = null;
      selectedLocationId = null;
      selectedBarrier = null;
      selectedDepartmentId = null;
      selectedZoneId = null;

      hazardList = [];
      subHazardList = [];
      departmentList = [];
      contractorList = [];
      locationList = [];
      barrierList = [];
      activityList = [];
      zoneList = [];

      return {
        selectedHazardId,
        selectedProjectId,
        selectedSubHazardId,
        selectedContractorId,
        selectedDepartmentId,
        selectedActivity,
        selectedLocationId,
        selectedZoneId,
        selectedBarrier,
        hazardList,
        subHazardList,
        projectList,
        contractorList,
        departmentList,
        activityList,
        locationList,
        barrierList,
        zoneList,
      };
    });
  };

  _fetchProjectList = async (serviceId) => {

    let requestData = {
      service_id: serviceId,
      // module_id: moduleId,
      module_name: "good_acts",
    };

    var ContentType = {
      "Content-Type": "application/x-www-form-urlencoded",
    };

    import("app/shared/utility/UtilFunction").then((utilFunction) => {
      let responseObj = utilFunction.sharedAxiosAPI(
        Url.projectListForm,
        ContentType,
        requestData
      );

      responseObj
        .then(async (response) => {
          if (response.data.status === 200) {
            await this.setState((prevState) => {
              let { projectList } = prevState;
              projectList = response.data.data;
              return { projectList };
            });
            if (this.state.userData.designation_flag === 4) {
              const { project_id, contractor_flag, zone_location_flag } = response.data.data[0];
              this.setState({
                selectedProjectId: project_id,
                // selectedContractorId: contractor_flag,
                showContractorDropdown: Boolean(contractor_flag),
                showLocationDropdown: Boolean(zone_location_flag),
                showZoneDropdown: Boolean(zone_location_flag),
                // selectedZoneId: zone_location_flag,
                // selectedLocationId: zone_location_flag
              }, () => {
                this._fetchOptionList();
              });
            }
          }
        })
        .catch((error) => {
          this._setDataLoading(false);
        });
    });
  };

  _projectChangeHandle = async (projectId) => {
    await this._resetDropdwon(false);

    await this.setState((prevState) => {
      let {
        selectedProjectId,
        projectError,
        showContractorDropdown,
        showZoneDropdown,
        showLocationDropdown,
        projectList,
      } = prevState;

      // check contractor flag
      let index = projectList.findIndex((item) => projectId == item.project_id);
      const isContractor = projectList[index].contractor_flag;
      showContractorDropdown = isContractor == 0 ? false : true;

      const isZone = projectList[index].zone_location_flag;
      showZoneDropdown = isZone == 0 ? false : true;

      const isLocation = projectList[index].zone_location_flag;
      showLocationDropdown = isLocation == 0 ? false : true;

      selectedProjectId = projectId;
      projectError = projectId ? false : true;

      return {
        selectedProjectId,
        projectError,
        showContractorDropdown,
        showZoneDropdown,
        showLocationDropdown,
      };
    });
    await this._fetchOptionList();
  };

  _zoneChangeHandle = async (zoneId) => {
    await this.setState((prevState) => {
      let {
        selectedZoneId,
        departmentError,
        contractorError,
        // locationError,
        zoneError,
      } = prevState;
      selectedZoneId = zoneId;
      zoneError = zoneId ? false : true;

      const isFieldShownMapAndValid = {
        isValidDepartment: !!selectedZoneId,
        isValidContactor: !!selectedZoneId,
        // isValidLocation: !!selectedZoneId,
        isValidZone: !!selectedZoneId,

        isShownDepartment: !Object.values(prevState.formHideField).includes(
          "Department"
        ),
        isShownContactor: prevState.showContractorDropdown,
        // isShownLocation: prevState.showLocationDropdown,
        isShownZone: prevState.showZoneDropdown,
      };

      if (isFieldShownMapAndValid.isShownDepartment) {
        departmentError = !selectedZoneId;
      }

      if (isFieldShownMapAndValid.isShownContactor) {
        contractorError = !selectedZoneId;
      }

      // if (isFieldShownMapAndValid.isShownLocation) {
      //   locationError = !selectedZoneId;
      // }
      return {
        selectedZoneId,
        departmentError,
        contractorError,
        // locationError,
        zoneError,
      };
    });
    await this._fetchLocationList();
  };

  _fetchLocationList = async (zoneId) => {
    const userId = localStorage.getItem("userId");
    const token = localStorage.getItem("token");

    let requestData = {
      auth_key: token,
      user_id: userId,
      service_id: this.state.selectedServiceId,
      project_id: this.state.selectedProjectId,
      zone_id: this.state.selectedZoneId,
    };
    var ContentType = {
      "Content-Type": "application/x-www-form-urlencoded",
    };

    import("app/shared/utility/UtilFunction").then((utilFunction) => {
      let responseObj = utilFunction.sharedAxiosAPI(
        Url.locationList,
        ContentType,
        requestData
      );

      responseObj
        .then(async (response) => {
          if (response.data.status === 200) {
            await this.setState((prevState) => {
              let { locationList } = prevState;
              locationList = response.data.data;
              return { locationList };
            });
          }
        })
        .catch((error) => {
          this._setDataLoading(false);
        });
    });
  };

  _fetchCategoryList = async (zoneId) => {
    const userId = localStorage.getItem("userId");
    const token = localStorage.getItem("token");

    let requestData = {
      auth_key: token,
      user_id: userId,
    };
    var ContentType = {
      "Content-Type": "application/x-www-form-urlencoded",
    };

    import("app/shared/utility/UtilFunction").then((utilFunction) => {
      let responseObj = utilFunction.sharedAxiosAPI(
        Url.goodPractices.categoryList,
        ContentType,
        requestData
      );

      responseObj
        .then(async (response) => {
          if (response.data.status === 200) {
            await this.setState((prevState) => {
              let { categoryList } = prevState;
              categoryList = response.data?.data?.category_list || [];
              return { categoryList };
            });
          }
        })
        .catch((error) => {
          this._setDataLoading(false);
        });
    });
  };

  _fetchSubHazardList = async () => {
    const userId = localStorage.getItem("userId");
    const token = localStorage.getItem("token");
    let requestData = {
      auth_key: token,
      user_id: userId,
      hazard_Id: this.state.selectedHazardId,
    };
    var ContentType = {
      "Content-Type": "application/x-www-form-urlencoded",
    };

    import("app/shared/utility/UtilFunction").then((utilFunction) => {
      let responseObj = utilFunction.sharedAxiosAPI(
        Url.subhazardList,
        ContentType,
        requestData
      );

      responseObj
        .then(async (response) => {
          if (response.data.status === 200) {
            await this.setState((prevState) => {
              let { subHazardList } = prevState;
              subHazardList = response.data.data;
              return { subHazardList };
            });
          }
        })
        .catch((error) => {
          this._setDataLoading(false);
        });
    });
  };

  _hazardChangeHandle = async (hazardId) => {
    await this.setState((prevState) => {
      let { selectedHazardId, hazardError } = prevState;
      selectedHazardId = hazardId;

      if (this.state.reportType === "soi") {
        hazardError = hazardId ? false : true;
      }

      return { selectedHazardId, hazardError };
    });
    await this._fetchSubHazardList();
  };

  _departmentChangeHandle = async (id) => {
    await this.setState((prevState) => {
      let {
        selectedDepartmentId,
        departmentError,
        contractorError,
        locationError,
        zoneError,
      } = prevState;
      selectedDepartmentId = id;
      departmentError = id ? false : true;
      const isFieldShownMapAndValid = {
        isValidDepartment: !!selectedDepartmentId,
        isValidContactor: !!selectedDepartmentId,
        isValidLocation: !!selectedDepartmentId,
        isValidZone: !!selectedDepartmentId,

        isShownDepartment: !Object.values(prevState.formHideField).includes(
          "Department"
        ),
        isShownContactor: prevState.showContractorDropdown,
        isShownLocation: prevState.showLocationDropdown,
        isShownZone: prevState.showZoneDropdown,
      };

      if (isFieldShownMapAndValid.isShownDepartment) {
        departmentError = !selectedDepartmentId;
      }

      if (isFieldShownMapAndValid.isShownContactor) {
        contractorError = !selectedDepartmentId;
      }

      if (
        isFieldShownMapAndValid.isShownLocation &&
        isFieldShownMapAndValid.isShownZone
      ) {
        locationError = !selectedDepartmentId;
        zoneError = !selectedDepartmentId;
      }

      return {
        selectedDepartmentId,
        departmentError,
        contractorError,
        locationError,
        zoneError,
      };
    });
  };

  _locationChangeHandle = async (id) => {
    this.setState((prevState) => {
      let {
        selectedLocationId,
        departmentError,
        contractorError,
        locationError,
      } = prevState;
      selectedLocationId = id;
      locationError = id ? false : true;

      const isFieldShownMapAndValid = {
        isValidDepartment: !!selectedLocationId,
        isValidContactor: !!selectedLocationId,
        isValidLocation: !!selectedLocationId,
        isValidZone: !!selectedLocationId,

        isShownDepartment: !Object.values(prevState.formHideField).includes(
          "Department"
        ),
        isShownContactor: prevState.showContractorDropdown,
        isShownLocation: prevState.showLocationDropdown,
        isShownZone: prevState.showZoneDropdown,
      };

      if (isFieldShownMapAndValid.isShownDepartment) {
        // departmentError = !prevState.selectedDepartmentId;
        departmentError = !selectedLocationId;
      }

      if (isFieldShownMapAndValid.isShownContactor) {
        // contractorError = !prevState.selectedContractorId;
        contractorError = !selectedLocationId;
      }

      return {
        selectedLocationId,
        locationError,
        departmentError,
        contractorError,
      };
    });
  };

  _subHazardChangeHandle = async (id) => {
    await this.setState((prevState) => {
      let { selectedSubHazardId } = prevState;
      selectedSubHazardId = id;
      //subHazardError = id ? false : true;
      return { selectedSubHazardId };
    });
  };

  _barrierChangeHandle = async (id) => {
    await this.setState((prevState) => {
      let { selectedBarrier, barrierError } = prevState;
      selectedBarrier = id;

      if (this.state.reportType === "soi") {
        barrierError = id ? false : true;
      }

      return { selectedBarrier, barrierError };
    });
  };

  _activityChangeHandle = async (id) => {
    await this.setState((prevState) => {
      let { selectedActivity, activityError } = prevState;
      selectedActivity = id;
      activityError = id ? false : true;
      return { selectedActivity, activityError };
    });
  };

  _categoryChangeHandle = async (id) => {
    await this.setState((prevState) => {
      let { selectedCategory, categoryError } = prevState;
      selectedCategory = id;
      categoryError = id ? false : true;
      return { selectedCategory, categoryError };
    });
  };

  _contractorChangeHandle = async (id) => {
    await this.setState((prevState) => {
      let {
        selectedContractorId,
        departmentError,
        contractorError,
        locationError,
        zoneError,
      } = prevState;
      selectedContractorId = id;
      contractorError = id ? false : true;

      const isFieldShownMapAndValid = {
        isValidDepartment: !!selectedContractorId,
        isValidContactor: !!selectedContractorId,
        isValidLocation: !!selectedContractorId,
        isValidZone: !!selectedContractorId,

        isShownDepartment: !Object.values(prevState.formHideField).includes(
          "Department"
        ),
        isShownContactor: prevState.showContractorDropdown,
        isShownLocation: prevState.showLocationDropdown,
        isShownZone: prevState.showZoneDropdown,
      };

      if (isFieldShownMapAndValid.isShownDepartment) {
        departmentError = !selectedContractorId;
      }

      if (isFieldShownMapAndValid.isShownContactor) {
        contractorError = !selectedContractorId;
      }

      if (
        isFieldShownMapAndValid.isShownLocation &&
        isFieldShownMapAndValid.isShownZone
      ) {
        locationError = !selectedContractorId;
        zoneError = !selectedContractorId;
      }
      return {
        selectedContractorId,
        departmentError,
        contractorError,
        locationError,
        zoneError,
      };
    });
  };

  _severityChangeHandle = async (id) => {
    await this.setState((prevState) => {
      let { selectedSeverity, severityError } = prevState;
      selectedSeverity = id;
      severityError = id ? false : true;
      return { selectedSeverity, severityError };
    });
  };

  _typeactChangeHandle = async (id) => {
    await this.setState((prevState) => {
      let { selectedTypeAct, typeActError } = prevState;
      selectedTypeAct = id;
      typeActError = id ? false : true;
      return { selectedTypeAct, typeActError };
    });
  };

  _typeIncidentChangeHandle = async (id) => {
    await this.setState((prevState) => {
      let { selectedIncidentTypeId, incidentTypeError } = prevState;
      selectedIncidentTypeId = id;
      incidentTypeError = id ? false : true;
      return { selectedIncidentTypeId, incidentTypeError };
    });
  };

  _evidenceFileHandle = async (event) => {
    var evidence_file = Array.from(event.target.files);
    event.target.value = "";

    const lowStorage = this.checkLowStorage();

    lowStorage
      .then(async (response) => {
        const { status, msg } = response.data;

        if (status == 200) {
          var tempUrl = [];
          var filePromise = new Promise((resolve, reject) => {
            evidence_file.forEach((item, index) => {
              let temVar;

              let reader = new FileReader();
              reader.readAsDataURL(item);
              reader.onloadend = () => {
                tempUrl.push(reader.result);
                if (index === evidence_file.length - 1) {
                  return resolve(1);
                }
              };
            });
          });

          filePromise.then(async () => {
            await this.setState((prevState) => {
              let { evidenceFileList, evidenceUrlList } = prevState;
              const tempArr = evidence_file.map((item) => item);
              evidenceFileList = [...evidenceFileList, ...tempArr];
              evidenceUrlList = [...evidenceUrlList, ...tempUrl];
              return { evidenceFileList, evidenceUrlList };
            });
          });
        }
        await this.setState({
          lowStorageStatus: status,
          lowStorageMessage: msg,
          showLowStoragePopup: status == 200 ? false : true,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  _handleChange = async (item = null, type = "observation") => {
    await this.setState((prevState) => {
      switch (type) {
        case "observation":
          let { observationText, observationError, observationURL } = prevState;
          observationText = item;
          observationError = !observationURL && !item ? true : false;
          return { observationText, observationError };
          break;

        case "person_name":
          let { personName, personNameError } = prevState;
          personName = item;
          personNameError = item ? false : true;
          return { personName, personNameError };
          break;

        default:
          return prevState;
          break;
      }
    });
  };

  _removePreviewFile = async (index = 0) => {
    await this.setState((prevState) => {
      let { evidenceFileList, evidenceUrlList } = prevState;
      evidenceFileList.splice(index, 1);
      evidenceUrlList.splice(index, 1);
      return { evidenceFileList, evidenceUrlList };
    });
  };

  checkLowStorage = () => {
    return import("app/shared/utility/UtilFunction").then((utilFunction) => {
      let responseObj = utilFunction.sharedAxiosAPI(
        Url.reportSetting.checkStorage
      );

      return responseObj;
    });
  };

  _handleLowSpace = async (statusFlag) => {
    await this.setState({ showLowStoragePopup: false });
  };

  _formValidation = async () => {
    const tempObj = this.state;

    await this.setState((prevState) => {
      prevState.observationError =
        tempObj.observationText || tempObj.observationURL ? false : true;

      prevState.serviceError = tempObj.selectedServiceId ? false : true;

      prevState.projectError = tempObj.selectedProjectId ? false : true;



      // DEPARTMENT/LOCATION/CONTRACTOR/ZONE validation START
      const isFieldShownMapAndValid = {
        isValidDepartment: !!tempObj.selectedDepartmentId,
        isValidContactor: !!tempObj.selectedContractorId,
        isValidLocation: !!tempObj.selectedLocationId,
        isValidZone: !!tempObj.selectedZoneId,

        isShownDepartment: !Object.values(this.state.formHideField).includes(
          "Department"
        ),
        isShownContactor: this.state.showContractorDropdown,
        isShownLocation: this.state.showLocationDropdown,
        isShownZone: this.state.showZoneDropdown,
      };

      if (
        isFieldShownMapAndValid.isShownDepartment &&
        isFieldShownMapAndValid.isShownContactor &&
        isFieldShownMapAndValid.isShownLocation &&
        isFieldShownMapAndValid.isShownZone
      ) {
        // dept, contractor, location
        if (isFieldShownMapAndValid.isValidDepartment) {
          prevState.departmentError =
            !isFieldShownMapAndValid.isValidDepartment;
          prevState.contractorError = false;
          prevState.locationError = false;
          prevState.zoneError = false;
        } else if (isFieldShownMapAndValid.isValidContactor) {
          prevState.contractorError = !isFieldShownMapAndValid.isValidContactor;
          prevState.departmentError = false;
          prevState.locationError = false;
          prevState.zoneError = false;
        } else if (
          isFieldShownMapAndValid.isValidZone &&
          isFieldShownMapAndValid.isValidLocation
        ) {
          prevState.departmentError = false;
          prevState.contractorError = false;
          prevState.locationError = !isFieldShownMapAndValid.isValidLocation;
          prevState.zoneError = !isFieldShownMapAndValid.isValidZone;
        } else {
          prevState.departmentError = true;
          prevState.contractorError = true;
          prevState.locationError = true;
          prevState.zoneError = true;
        }
      } else if (
        isFieldShownMapAndValid.isShownDepartment &&
        isFieldShownMapAndValid.isShownContactor
      ) {
        // dept, contractor
        if (isFieldShownMapAndValid.isValidDepartment) {
          prevState.departmentError =
            !isFieldShownMapAndValid.isValidDepartment;
          prevState.contractorError = false;
        } else if (isFieldShownMapAndValid.isValidContactor) {
          prevState.contractorError = !isFieldShownMapAndValid.isValidContactor;
          prevState.departmentError = false;
        } else {
          prevState.contractorError = true;
          prevState.departmentError = true;
        }
      } else if (
        isFieldShownMapAndValid.isShownDepartment &&
        isFieldShownMapAndValid.isShownLocation &&
        isFieldShownMapAndValid.isShownZone
      ) {
        // dept, location
        if (isFieldShownMapAndValid.isValidDepartment) {
          prevState.departmentError =
            !isFieldShownMapAndValid.isValidDepartment;
          prevState.locationError = false;
          prevState.zoneError = false;
        } else if (
          isFieldShownMapAndValid.isValidZone &&
          isFieldShownMapAndValid.isValidLocation
        ) {
          prevState.departmentError = false;
          prevState.locationError = !isFieldShownMapAndValid.isValidLocation;
          prevState.zoneError = !isFieldShownMapAndValid.isValidZone;
        } else {
          prevState.departmentError = true;
          prevState.locationError = true;
          prevState.zoneError = true;
        }
      } else if (
        isFieldShownMapAndValid.isShownContactor &&
        isFieldShownMapAndValid.isShownLocation &&
        isFieldShownMapAndValid.isShownZone
      ) {
        // contractor, location
        if (isFieldShownMapAndValid.isValidContactor) {
          prevState.contractorError = !isFieldShownMapAndValid.isValidContactor;
          prevState.departmentError = false;
          prevState.locationError = false;
          prevState.zoneError = false;
        } else if (
          isFieldShownMapAndValid.isValidZone &&
          isFieldShownMapAndValid.isValidLocation
        ) {
          prevState.departmentError = false;
          prevState.contractorError = false;
          prevState.locationError = !isFieldShownMapAndValid.isValidLocation;
          prevState.zoneError = !isFieldShownMapAndValid.isValidZone;
        } else {
          prevState.contractorError = true;
          prevState.locationError = true;
          prevState.zoneError = true;
        }
      } else if (isFieldShownMapAndValid.isShownDepartment) {
        // dept
        prevState.departmentError = !isFieldShownMapAndValid.isValidDepartment;
      } else if (isFieldShownMapAndValid.isShownContactor) {
        // contractor
        prevState.departmentError = !isFieldShownMapAndValid.isValidContactor;
      } else if (
        isFieldShownMapAndValid.isShownLocation &&
        isFieldShownMapAndValid.isShownZone
      ) {
        // location
        prevState.locationError = !isFieldShownMapAndValid.isValidLocation;
        prevState.zoneError = !isFieldShownMapAndValid.isValidZone;
      }
      // DEPARTMENT/LOCATION/CONTRACTOR/ZONE validation END

      prevState.activityError = tempObj.selectedActivity ? false : true;
      prevState.categoryError = tempObj.selectedCategory ? false : true;

      return prevState;
    });
  };

  _uploadFile = async (file) => {

    this.setState({
      selectedFile: file,
    });
  };

  _submitGoodPractice = async () => {
   
    await this._formValidation();

    // Checking form have any Error
    let formErrorStatus;
    for (var propertyName in this.state) {
      if (propertyName.includes("Error")) {
        if (this.state[propertyName]) {

          formErrorStatus = true;
          return;
        } else {
          formErrorStatus = false;
        }
      }
    }

    // If form not have error then submit form
    if (!formErrorStatus) {
      // this.props.parentStateSetter(({
      //   apiLoading:true
      // }));
      const userId = localStorage.getItem("userId");
      const token = localStorage.getItem("token");

      await this.setState({ dataLoading: true });

      this._setDataLoading(true);

      let soiData = new FormData();
      soiData.append("auth_key", token);
      soiData.append("user_id", userId);
      soiData.append("service_id", this.state.selectedServiceId || 0);
      soiData.append("project_id", this.state.selectedProjectId || 0);

      soiData.append("category", this.state.selectedCategory || 0);

      soiData.append("activity", this.state.selectedActivity || 0);
      const { voilatorPersonList } = this.state;
      if (this.state.searchFiledUsers.length) {
        this.state.searchFiledUsers.forEach((el) => {
          soiData.append(
            "incident_person_id",
            el.employeeId
          );
          soiData.append(
            "searched_incident_person_id",
            el.incident_person_id
          );
          soiData.append(
            "incident_person_name",
            el?.incident_person_name
          );
        })
      }

      voilatorPersonList.forEach((el, idx) => {
        soiData.append(
          "incident_person_name",
          el
        );
      })
      this.state.voilatorIdList.forEach((el) => {
        soiData.append(
          "incident_person_id",
          el
        );
      })
      soiData.append("zone", this.state.selectedZoneId || 0);
      soiData.append("location", this.state.selectedLocationId || 0);
      soiData.append("department", this.state.selectedDepartmentId || 0);
      soiData.append("contractor", this.state.selectedContractorId || 0);
      soiData.append("observation", this.state.observationText || "");

      soiData.append("lat", this.state.lat);
      soiData.append("lang", this.state.long);
      if (this.state.observationAudio) {
        soiData.append("observation_record", this.state.observationAudio);
      }

      // Evidence File

      soiData.append("file", this.state.selectedFile || "");
      soiData.append("file_status", this.state.selectedFile ? 1 : 0);

      // if (this.state.evidenceFileList.length) {
      //   this.state.evidenceFileList.map((data) => {
      //     soiData.append("file", data);
      //   });
      //   soiData.append("file_status", 1);
      // } else {
      //   soiData.append("file_status", 0);
      // }

      var ContentType = {
        "Content-Type": "multipart/form-data",
      };

      let URL = Url.goodPractices.addGoodPractice;

      await axios
        .post(URL, soiData, ContentType)
        .then(async (response) => {
          this._setDataLoading(false);
          this.setState({
            clearDropZone: true
          });
          if (response.data.status === 200) {
            await this._continueReportingClearForm();
            await this.setState({
              reportNumber: response.data.report_number,
              sucesssPopup: true,
            });
          } else {
            await this._continueReportingClearForm();
          }
        })
        .catch((error) => {
          this._setDataLoading(false);

        })
        // .finally(() =>{
        //   this.props.parentStateSetter(({
        //     apiLoading:false
        //   }));
        // })
    }
  };

  _showRecorder = async (flag = false, type = "observation") => {
    if (flag) {
      const lowStorage = this.checkLowStorage();
      lowStorage
        .then(async (response) => {
          const { status, msg } = response.data;

          if (status == 200) {
            await this.setState((prevState) => {
              let { showRecorderModal, recordingFor } = prevState;
              showRecorderModal = flag;
              recordingFor = type;
              return { showRecorderModal, recordingFor };
            });
          }
          await this.setState({
            lowStorageStatus: status,
            lowStorageMessage: msg,
            showLowStoragePopup: status == 200 ? false : true,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      await this.setState((prevState) => {
        let { showRecorderModal, recordingFor } = prevState;
        showRecorderModal = flag;
        recordingFor = type;
        return { showRecorderModal, recordingFor };
      });
    }
  };

  startRecording = async () => {
    await this.setState({ recordAudio: true });
  };

  stopRecording = async () => {
    await this.setState({ recordAudio: false });
  };

  onStop = async (recordedBlob) => {
    let promise = new Promise((resolve, error) => {
      let reader = new FileReader();
      reader.readAsDataURL(recordedBlob.blob);
      reader.onloadend = () => {
        resolve(reader.result);
      };
    });

    promise.then(async (response) => {
      await this.setState((prevState) => {
        let {
          controlMeasureAudio,
          controlMeasureURL,
          observationAudio,
          observationURL,
        } = prevState;
        if (this.state.recordingFor === "control_measure") {
          controlMeasureAudio = recordedBlob.blob;
          controlMeasureURL = response;
        }
        if (this.state.recordingFor === "observation") {
          observationAudio = recordedBlob.blob;
          observationURL = response;

          console.log(observationURL, observationAudio);
        }
        return {
          controlMeasureAudio,
          controlMeasureURL,
          observationAudio,
          observationURL,
        };
      });
    });
  };

  _setDataLoading = async (status) => {
    await this.setState((prevState) => {
      let { dataLoading } = prevState;
      dataLoading = status;
      return { dataLoading };
    });
  };

  _removeAudioFile = async (type) => {
    await this.setState((prevState) => {
      if (type === "observation") {
        prevState.observationAudio = "";
        prevState.observationURL = "";
        return prevState;
      }

      if (type === "control_measure") {
        prevState.controlMeasureAudio = "";
        prevState.controlMeasureURL = "";
        return prevState;
      }
    });
  };

  _onTimeChange = async (options) => {
    const { hour, minute, meridiem } = options;

    const time = `${hour}:${minute}:00`;
    const formattedTime = moment(time, "HH:mm:").format("hh:mm");

    await this.setState({
      selectedIncidentTime: formattedTime,
      selectedTimeMeridiem: meridiem,
    });
  };

  _reportType = () => {
    if (this.state.reportType == "nm") {
      return "NM";
    } else if (this.state.reportType == "ira") {
      return "IRA";
    } else {
      return "UA/UC";
    }
  };

  _clearForm = async () => {
    await this.setState((prevState) => {
      prevState.typeActList = TYPE_ACT;
      prevState.typeIncidentList = INCIDENT_TYPE;
      prevState.severityList = SEVERITY;
      prevState.projectList = [];
      prevState.departmentList = [];
      prevState.contractorList = [];
      prevState.zoneList = [];
      prevState.locationList = [];
      prevState.hazardList = [];
      prevState.activityList = [];
      prevState.barrierList = [];
      prevState.subHazardList = [];
      prevState.voilatorPersonList = [];
      prevState.voilatorIdList = [];
      prevState.observationText = "";
      prevState.evidenceFileList = [];
      prevState.evidenceUrlList = [];
      prevState.selectedServiceId = this.state.userData.designation_flag === 4 ? prevState.selectedServiceId : null;
      prevState.selectedProjectId = this.state.userData.designation_flag === 4 ? prevState.selectedProjectId : null;
      prevState.selectedDepartmentId = null;
      prevState.selectedContractorId = null;
      prevState.selectedZoneId = null;
      prevState.selectedLocationId = null;
      prevState.selectedTypeAct = null;
      prevState.selectedActivity = null;
      prevState.selectedSeverity = null;
      prevState.selectedBarrier = null;
      prevState.selectedHazardId = null;
      prevState.selectedSubHazardId = null;
      prevState.selectedIncidentTypeId = 1;

      prevState.selectedTargetDate = new Date();
      prevState.controlMeasure = "";
      prevState.resolveCheckbox = false;
      prevState.soiFormHidingField = {};
      prevState.nmFormHidingField = {};
      prevState.iraFormHidingField = {};

      prevState.serviceError = false;
      prevState.projectError = false;

      prevState.zoneError = false;
      prevState.departmentError = false;
      prevState.contractorError = false;
      prevState.locationError = false;

      prevState.typeActError = false;
      prevState.activityError = false;
      prevState.severityError = false;
      prevState.barrierError = false;
      prevState.hazardError = false;
      prevState.observationError = false;

      prevState.showRecorderModal = false;
      prevState.recordAudio = false;
      prevState.controlMeasureAudio = "";
      prevState.controlMeasureURL = "";
      prevState.observationAudio = "";
      prevState.observationURL = "";
      prevState.recordingFor = "observation";

      prevState.selectedIncidentTime = "10:00";
      prevState.selectedIncidentDate = new Date();
      prevState.selectedTimeMeridiem = "AM";
      prevState.personName = "";

      return prevState;
    });
  };

  _continueReportingClearForm = async () => {
    await this.setState((prevState) => {
      prevState.typeActList = TYPE_ACT;
      prevState.typeIncidentList = INCIDENT_TYPE;
      prevState.severityList = SEVERITY;

      prevState.observationText = "";
      prevState.evidenceFileList = [];
      prevState.evidenceUrlList = [];
      prevState.selectedServiceId = null;
      prevState.selectedProjectId = null;
      prevState.selectedDepartmentId = null;
      prevState.selectedContractorId = null;
      prevState.selectedZoneId = null;
      prevState.selectedLocationId = null;
      prevState.clearDropZone = false;
      prevState.selectedFile = "";
      
      prevState.selectedActivity = null;
      prevState.selectedCategory = null;
      prevState.voilatorPersonList = []
      prevState.soiFormHidingField = {};
      prevState.nmFormHidingField = {};
      prevState.voilatorIdList = [];
      prevState.serviceError = false;
      prevState.projectError = false;

      prevState.zoneError = false;
      prevState.departmentError = false;
      prevState.contractorError = false;
      prevState.locationError = false;

      prevState.activityError = false;
      prevState.categoryError = false;
      prevState.observationError = false;

      prevState.showRecorderModal = false;
      prevState.recordAudio = false;
      prevState.observationAudio = "";
      prevState.observationURL = "";
      prevState.recordingFor = "observation";

      // clearing dropdown values
      prevState.serviceList = [];
      prevState.projectList = [];
      prevState.categoryList = [];
      prevState.departmentList = [];
      prevState.contractorList = [];
      prevState.zoneList = [];
      prevState.locationList = [];
      prevState.activityList = [];
      return prevState;
    });
  };

  _addPersonName = async () => {
    await this.setState((prevState) => {
      let { personName, personNameList } = prevState;
      personNameList = [...personNameList, personName];
      personName = "";
      return { personName, personNameList };
    });
  };

  _removePersonName = async (position) => {
    await this.setState((prevState) => {
      let { personNameList } = prevState;
      personNameList.splice(position, 1);
      return { personNameList };
    });
  };
  getAlert = () => {
    alert(this.props.t('getAlertFromChild'));
  }
  _manageVoilatorState = (val, type) => {
    const { voilatorIdList, voilatorPersonList } = this.state;
    const temp = Array.from(type === 2 ? voilatorIdList : voilatorPersonList)
    temp.push(val);
    this.setState({
      [type === 2 ? 'voilatorIdList' : 'voilatorPersonList']: temp,
      [type === 2 ? 'voilatorId' : 'voilatorPersonName']: ""
    });
  }

  removeVoilator = (voilatorId, type = 1) => {
    const { voilatorIdList, voilatorPersonList } = this.state;
    const temp = [...Array.from(type === 2 ? voilatorIdList : voilatorPersonList)];
    temp.splice(temp.indexOf(voilatorId), 1);
    this.setState({
      [type === 2 ? 'voilatorIdList' : 'voilatorPersonList']: temp,
    });
  }
  searchEmployee = async (inputString, callback) => {
    const contentType = { "Content-Type": "application/x-www-form-urlencoded" };
    await axios
      .post(Url.userSearch, qs.stringify({
        service_id: this.state.selectedServiceId,
        auth_key: localStorage.getItem("token"),
        project_id: this.state.selectedProjectId,
        user_id: localStorage.getItem("userId"),
        search_user_name: inputString
      }), contentType)
      .then(async (response) => {
        if (response.data.status === 200) {
          callback(response.data)
          await this.setState({
            searchData: { ...this.state.searchData, ...response.data },
          });
        }
      })
      .catch((error) => {

      });
  }
  render() {
    const isOberserver = this.state.userData.designation_flag === 4;
    const {t} = this.props;
    return (
      <>
        {/* Data Loading  */}
        
        <div className="rd-content">
          <div className="rd-left-content">
            <div className="rd-box">
              <Row>
                <Col>
                  {/* <div className="form-group">
                    <div className="file cursor-pointer file-input">
                      Upload Evidence
                      <input
                        type="file"
                        name="file"
                        onChange={(event) => this._evidenceFileHandle(event)}
                        multiple
                        accept="image/*,video/*"
                      />
                    </div>
                    <div className="images-section mt-2">
                      {!!this.state.evidenceUrlList.length &&
                        this.state.evidenceUrlList.map((item, index) => (
                          <div
                            className="image-preview"
                            key={`soi-evidence-image-${index}`}
                          >
                            <div className="preview-box">
                              <img src={item} alt="evidence_image" />
                              <Button
                                variant="outline-primary"
                                className="close-btn"
                                onClick={() => this._removePreviewFile(index)}
                              >
                                X
                              </Button>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div> */}

                  <div className="form-group">
                    {/* <label className="form-label">Image/Video</label> */}
                    <div className="padding-l-r">
                      <MyDropzone type="5" uploadFile={this._uploadFile} disabledPlaceHolder clearDropZone={this.state.clearDropZone} />
                    </div>
                  </div>

                  <div className={`slds-form-element padding-l-r ${this.state.observationError ? "slds-has-error" : ""}`}>
                    <div className="rd-box-head">
                      <div className="with-mic">
                        <label className="slds-form-element__label">
                          {t('observation')}
                          <abbr className="slds-required" title="required">* </abbr>
                        </label >
                      </div>
                      {this.state.observationURL ? (
                        <div className="audioPreviewer mt-2">
                          <AudioPlayer
                            src={this.state.observationURL}
                            showJumpControls={true}
                            layout="horizontal-reverse"
                            customVolumeControls={[]}
                            customAdditionalControls={[]}
                            style={{
                              width: "600px",
                            }}
                          />
                          <Button
                            variant="outline-primary"
                            onClick={() => this._removeAudioFile("observation")}
                          >
                            X
                          </Button>
                        </div>
                      ) : null}
                    </div>
                    <div className="slds-form-element__control">
                      <textarea
                        className="slds-textarea"
                        onChange={(event) =>
                          this._handleChange(event.target.value, "observation")
                        }
                        value={this.state.observationText}
                        placeholder={t('enterGoodActDescription')}
                      ></textarea>
                    </div>
                    {!!this.state.observationError && (
                      <div class="slds-form-element__help" id="error-01">{t('requiredMessage')}</div>
                    )}
                  </div>
                  <div>
                    {/* <div className="rd-box-head">
                      <h3>Service & Plant/Project</h3>
                    </div> */}
                    {!isOberserver ? <Row>
                      <Col>
                        <ServiceList
                          optionList={this.state.serviceList}
                          onChangeHandle={this._serviceChangeHandle}
                          value={this.state.selectedServiceId}
                          hasError={this.state.serviceError}
                        />
                      </Col>
                      <Col>
                        <ProjectList
                          optionList={this.state.projectList}
                          onChangeHandle={this._projectChangeHandle}
                          value={this.state.selectedProjectId}
                          hasError={this.state.projectError}
                        />
                      </Col>
                    </Row> : null}
                  </div>
                  <div>
                    {/* <div className="rd-box-head">
                      <h3>Department/Contractor/Location</h3>
                    </div> */}
                    <Row>
                      {!!!Object.values(this.state.formHideField).includes(
                        "Department"
                      ) && (
                          <Col sm={6}>
                            <CommonDropdown
                              title={t('department')}
                              optionList={this.state.departmentList}
                              onChangeHandle={this._departmentChangeHandle}
                              isRequired={true}
                              value={this.state.selectedDepartmentId}
                              hasError={this.state.departmentError}
                            />
                          </Col>
                        )}
                      {!!(
                        this.state.showContractorDropdown &&
                        !Object.values(this.state.formHideField).includes(
                          "Contractor"
                        )
                      ) && (
                          <Col sm={6}>
                            <CommonDropdown
                              title={t('contractor')}
                              optionList={this.state.contractorList}
                              onChangeHandle={this._contractorChangeHandle}
                              isRequired={true}
                              value={this.state.selectedContractorId}
                              hasError={this.state.contractorError}
                            />

                          </Col>
                        )}

                      {!!(
                        this.state.showZoneDropdown &&
                        !Object.values(this.state.formHideField).includes(
                          "Zone"
                        ) &&
                        !Object.values(this.state.formHideField).includes(
                          "zone"
                        )
                      ) && (
                          <Col sm={6}>
                            <CommonDropdown
                              title={t('zone')}
                              optionList={this.state.zoneList}
                              onChangeHandle={this._zoneChangeHandle}
                              isRequired={true}
                              value={this.state.selectedZoneId}
                              hasError={this.state.zoneError}
                            />
                          </Col>
                        )}
                      {!!(
                        this.state.showLocationDropdown &&
                        !Object.values(this.state.formHideField).includes(
                          "Location"
                        ) &&
                        !Object.values(this.state.formHideField).includes(
                          "location"
                        )
                      ) && (
                          <Col sm={6}>
                            <CommonDropdown
                              title={t('location')}
                              optionList={this.state.locationList}
                              onChangeHandle={this._locationChangeHandle}
                              isRequired={true}
                              value={this.state.selectedLocationId}
                              hasError={this.state.locationError}
                            />

                          </Col>
                        )}
                    </Row>
                  </div>
                </Col>
                <Col>
                  <div>
                    {/* <div className="rd-box-head">
                      <h3>Good Practices Details</h3>
                    </div> */}
                    <Row>
                      <Col sm={6}>
                        <CommonDropdown
                          title={t('activity')}
                          optionList={this.state.activityList}
                          onChangeHandle={this._activityChangeHandle}
                          value={this.state.selectedActivity}
                          hasError={this.state.activityError}
                        />

                      </Col>
                      <Col sm={6}>
                        <CommonDropdown
                          title={t('category')}
                          optionList={this.state.categoryList}
                          onChangeHandle={this._categoryChangeHandle}
                          value={this.state.selectedCategory}
                          hasError={this.state.categoryError}
                        />
                      </Col>
                      <Col sm={6} className="my-2">
                        <div className="slds-form-element">
                          <label className="slds-form-element__label">
                          {t('goodPersonName')}
                          </label>
                          <div className="d-flex slds-form-element__control">
                            <input
                              className={`slds-input col-lg-11 border`}
                              value={this.state.voilatorPersonName}
                              style={{
                                maxWidth: "11vw"
                              }}
                              onChange={(event) =>
                                this.setState({ voilatorPersonName: event.target.value })
                              }
                              placeholder={t('enterGoodPersonName')}
                            />

                            <ErrorBlock
                              message={t('enterAtLeastOneIncidentPerosonName')}
                            />

                            {/* <img src={require("assets/sls-icons/V2. New Development/ADD2.svg")} className=" ml-2 cursor-pointer svg-icons" onClick={() => this.state.voilatorPersonName !== "" && this._manageVoilatorState(this.state.voilatorPersonName, 1)} /> */}
                          </div>
                          {this.state.voilatorPersonList.map(
                            (personItem, personIndex) => (
                              <div
                                className="d-flex justify-content-between mt-2"
                                key={`person-${personIndex}`}
                              >
                                <span>{personItem}</span>
                                {/* <XCircle
                                  className="cursor-pointer"
                                  onClick={() =>
                                    this.removeVoilator(personIndex)
                                  }
                                /> */}
                              </div>
                            )
                          )}
                        </div>

                      </Col>
                      <Col sm={6} className="my-2">
                        <div className="slds-form-element">
                          <label className="slds-form-element__label">
                           {t('goodPersonID')}
                          </label>
                          <div className="d-flex slds-form-element__control">
                            <input
                              className={`slds-input col-lg-11 ${this.state.incidentPersonNameError
                                ? "border"
                                : "border"
                                }`}
                              value={this.state.voilatorId}
                              style={{
                                maxWidth: "11vw"
                              }}
                              onChange={(event) =>
                                this.setState({ voilatorId: event.target.value })
                              }
                              placeholder={t('enterGoodPersonID')}
                            />

                            <ErrorBlock
                              message={t('enterAtLeastOneIncidentPerosonName')}
                            />
                            <img src={require("assets/sls-icons/V2. New Development/ADD2.svg")} className=" ml-2 cursor-pointer svg-icons" onClick={() => {
                              if (this.state.voilatorPersonName !== "" && this.state.voilatorId !== "") {
                                this._manageVoilatorState(this.state.voilatorPersonName, 1);
                                this._manageVoilatorState(this.state.voilatorId, 2)
                              }
                            }} />
                          </div>
                          {this.state.voilatorIdList.map(
                            (personItem, personIndex) => (
                              <div
                                className="d-flex justify-content-between mt-2"
                                key={`person-${personIndex}`}
                              >
                                <span>{personItem}</span>
                                <XCircle
                                  className="cursor-pointer"
                                  onClick={() => {
                                    this.removeVoilator(personIndex)
                                    this.removeVoilator(personIndex, 2)
                                  }

                                  }
                                />
                              </div>
                            )
                          )}
                        </div>
                      </Col>
                      {this.state.selectedServiceId !== null && this.state.selectedProjectId !== null ? <Col sm={12} className="font-weight-light my-2">
                        <AsyncSelect
                          isMulti
                          cacheOptions
                          onChange={(data) => {
                            console.log(data);
                            const items = data?.map((el) => {
                              return {
                                ...el,
                                incident_person_id: el?.value,
                                incident_person_name: el?.label
                              }
                            });
                            this.setState({
                              searchFiledUsers: items
                            })
                          }}
                          placeholder={t('searchGoodPersonName')}
                          loadOptions={(input, callback) => {
                            if (this.timeout) clearTimeout(this.timeout);
                            this.timeout = setTimeout(() => {
                              this.searchEmployee(input, (data) => {
                                const items = data?.data.map((element) => {
                                  return {
                                    ...element,
                                    label: element?.name,
                                    value: element?.id
                                  }
                                }
                                )
                                callback(items)
                              })
                            }, 1000);

                          }}
                        />
                      </Col> : null

                      }
                    </Row>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
        {/* <div className="publish-btn">
          <Button
            variant="secondary"
            onClick={this.props.close}
            className="gray-btn"
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={this._submitGoodPractice}
            className="red-btn"
          >
            REPORT
          </Button>
        </div> */}

        <Modal
          show={this.state.showRecorderModal}
          dialogClassName="modal-90w"
          aria-labelledby="Voice Recording"
          onHide={() => this._showRecorder(false)}
          className="recordingModal"
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>{t('record')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <Button onClick={this.startRecording} className="red-btn">
                  <Mic />
                </Button>
                <Button onClick={this.stopRecording} className="gray-btn">
                  <MicOff />
                </Button>
              </div>
              <div className="obervationRecording"></div>
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          show={this.state.showRecorderModal}
          dialogClassName="modal-90w"
          aria-labelledby="Voice Recording"
          onHide={() => this._showRecorder(false)}
          className="recordingModal"
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <div className="obervationRecording">
              <ReactMic
                record={this.state.recordAudio}
                className="sound-wave"
                onStop={this.onStop}
                strokeColor="#000000"
                mimeType="audio/mp3"
                backgroundColor="#FF4081"
              />
            </div>

            <div className="modal-footer-btn">
              <Button onClick={this.startRecording} className="red-btn">
                <Mic /> {t('startRecording')}
              </Button>
              <Button onClick={this.stopRecording} className="gray-btn">
                <MicOff /> {t('stopRecording')}
              </Button>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          centered
          show={this.state.sucesssPopup}
          className="recordingModal"
          onHide={this._closeSuccessPopup}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Body className="text-center">
            <img
              className="submit-img"
              // src={require("assets/images/submit.png")}
              src={SubmitImage}
              alt="submit_image"
            />
            <p className="report-submit-text"> {t('thankYouForReporting')} </p>
            <p className="report-submit-text"> {t('youHaveReported')} </p>
            <p className="report-submit-text">
              {this.state.reportNumber || 0} {t('goodPracticesTillToday')}
            </p>
            <div className="modal-footer-btn">
              <button
                type="submit"
                className="red-btn"
                onClick={this._closeSuccessPopup}
              >
                {t('gotoDashboardAUC')}
              </button>
              <button
                type="submit"
                className="red-btn"
                onClick={this._continueReporting}
              >
                {t('continueReportingAUC')}
              </button>
            </div>
          </Modal.Body>
        </Modal>

        {this.state.showLowStoragePopup ? (
          <LowSpace
            message={this.state.lowStorageMessage}
            handleLowSpace={this._handleLowSpace}
          />
        ) : null}
      </>
    );
  }
}

export default compose(
  withRouter,
  withTranslation(["report","util"])
)(GPReport);
