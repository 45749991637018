/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  GOOD_PRACTICES,
  IRA,
  NM,
  SAFETY_THOUGHTS,
  TOP_PERFORMER,
  TRAINING,
  UAUC,
} from "app/shared/constants/ModuleNames";
import { Modal, Nav, Tab } from "react-bootstrap";
import _ from "lodash"
import TourIReport from "./TourIReport";
import React, { Component } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import GPReport from "./GPReport";
import LoadingImage from "app/shared/components/Loader/LoadingImage";
import NmReport from "./NmReport";
import TrainingReport from "./TrainingReport";
import Url from "app/shared/constants/Url";
import { checkPermittedRoute } from "app/shared/utility/UtilFunction";
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
//assetsV2
import { IReportCroxIcon, IReportTourIcon } from "assets/Iconsv2/Iconsv2";
const mapStateToProps = (state) => {
  const data = [...state.permitModule.permittedModuleList];
  return {
    permittedModuleList: data,
  };
};

class ReportModal extends Component {
  constructor(props) {
    super(props);
    const {t} = this.props;
    this.traningRef = React.createRef();
    this.gpRef = React.createRef();
    this.otherRef = React.createRef();
    this.state = {
      show: false,
      dataLoading: true,
      apiLoading: false,
      reportFeeds: [],
      reportFeedsLoading: true,
      isV2Enabled: this.props?.isVerisonV2 ?? false,
      activeModule: this.props?.activeModule ?? {},
      currentTab: "soi",
      error: false,
      hasMore: true,
      isLoading: false,
      flag: "open",
      graphIndex: null,
      otherDashboardDetails: [],
      sourceList: [],
      otherDetailsLoaded: false,
      rightSideBarType: "performers",
      safetyImages: [],
      safetyThought: "",
      maxFilesAttachLimit: 5,
      safetyThoughtSubmited: false,
      showDeletePopup: false,
      selectedId: null,
      days_since_last_incidence: "",
      reportingSOIPopup: false,
      startDate: new Date(),
      formParameter: [],
      serviceList: [],
      soiFormHidingField: {},
      nmFormHidingField: {},
      iraFormHidingField: {},
    };
  }

  closeModal = () => {
    this.props.handleClose();
  };

  componentDidMount = async () => {
    await this._fetchFormParamList();
    await this._fetchSourceList();

    //await this._fetchServiceList();
    if(this.props?.isVerisonV2){
      this.setState({currentTab:this.props?.activeModule?.key})
    }
  };

  _fetchFormParamList = async () => {
    import("app/shared/utility/UtilFunction").then((utilFunction) => {
      let responseObj = utilFunction.sharedAxiosAPI(
        Url.reportSetting.formParameter
      );

      responseObj
        .then(async (response) => {
          if (response.data.status === 200) {
            await this.setState((prevState) => {
              let {
                formParameter,
                soiFormHidingField,
                nmFormHidingField,
                iraFormHidingField,
              } = prevState;
              formParameter = response.data.data;
              formParameter.soiSetting.forEach((element, index) => {
                soiFormHidingField[index] = element.parameter;
              });

              formParameter.nmSetting.forEach((element, index) => {
                nmFormHidingField[index] = element.parameter;
              });

              formParameter.iraSetting.forEach((element, index) => {
                iraFormHidingField[index] = element.parameter;
              });

              return {
                formParameter,
                soiFormHidingField,
                nmFormHidingField,
                iraFormHidingField,
              };
            });
            this._setDataLoading(false);
          }
        })
        .catch((error) => {
          this._setDataLoading(false);
        });
    });
  };
  _fetchSourceList = async () => {
    import("app/shared/utility/UtilFunction").then((utilFunction) => {
      let responseObj = utilFunction.sharedAxiosAPI(
        Url.clientURL + "/master/sourcelist"
      );

      responseObj
        .then(async (response) => {
          if (response.data.status === 200) {
            await this.setState({ sourceList: response.data.data });
            this._setDataLoading(false);
          }
        })
        .catch((error) => {
          this._setDataLoading(false);
        });
    });
  };

  _setDataLoading = async (status) => {
    await this.setState((prevState) => {
      let { dataLoading } = prevState;
      dataLoading = status;
      return { dataLoading };
    });
  };

  _handleTabClick = async (selectedKey = "soi") => {
    console.log(selectedKey);
  };

  _handlePermittedRoute = (checkRoute) =>
    checkPermittedRoute(this.props.permittedModuleList, checkRoute);
  __buildTabs = () => {
    return [
      {
        tabName: "UA/UC",
        key: "soi",
        skip: !this._handlePermittedRoute(UAUC),
        keyClass: "ir1",
      },
      {
        tabName: "NM",
        key: "nm",
        skip: !this._handlePermittedRoute(NM),
        keyClass: "ir2",
      },
      {
        tabName: "IRA",
        key: "ira",
        skip: !this._handlePermittedRoute(IRA),
        keyClass: "ir3",
      },
      {
        tabName: "Good Act",
        key: "gp",
        skip: !this._handlePermittedRoute(GOOD_PRACTICES),
        keyClass: "ir4",
      },
      {
        tabName: "Training/TBT",
        key: "trn",
        skip: !this._handlePermittedRoute(TRAINING),
        keyClass: "ir5",
      },
    ];
  };
  __gpEmitter = {};
  __trainingEmitter = {};
  __nmEmitter = {};
  __evenetEmitter = (tab) => {
    if (tab === "gp") {
      this.__gpEmitter();
    } else if (tab === "trn") {
      this.__trainingEmitter();
    } else {
      this.__nmEmitter();
    }
  };

  debounceFunc = _.debounce(function (callback) {
    callback();
  }, 300);
  render() {
    return (
      <div>
        <Modal
          show={true}
          onHide={this.props.handleClose}
          className="reportPopup modal-50"
          backdrop="static"
          keyboard={false}
          size={"xl"}
          
        >
          <Modal.Header
            className={`w-100 ${this.state.isV2Enabled ? "bg-none" : ""}`}
          >
            <Modal.Title className={`w-100`}>
              <div className="align-items-center d-flex justify-content-between">
                {!this.state.isV2Enabled ? (
                  <>
                    <HorizontalNavTab
                      tabs={this.__buildTabs()}
                      activeItem={this.state.currentTab}
                      handleChange={(key) => this.setState({ currentTab: key })}
                    />
                    <div className="modal-controls">
                      <button onClick={this.closeModal}>Cancel</button>
                      <button
                        onClick={() =>
                          this.__evenetEmitter(this.state.currentTab)
                        }
                      >
                        Report
                      </button>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="clide-v2-oreange-1">
                      {this.state.activeModule.tabName}
                    </div>
                    <div className="align-items-center d-flex justify-content-between">
                      {/* <TourIReport
                        isVersionV2={this.state.isV2Enabled}
                        content={<IReportTourIcon />}
                      />{" "} */}
                      <div onClick={this.closeModal} className="cursor-p">
                        <IReportCroxIcon />
                      </div>
                    </div>
                  </>
                )}
              </div>
            </Modal.Title>
          </Modal.Header>
          <Scrollbars style={{ height: 480 }} autoHide={false} renderView={(props) => (
                <div
                  style={{
                    
                    ...props.style,
                    overflowX: "hidden",
                  }}
                />
              )}>
            <Modal.Body className="px-4 pt-4">
              {/* Data Loading  */}
              <div
                className="loader"
                style={{ display: this.state.dataLoading ? "block" : "none" }}
              >
                <LoadingImage />
              </div>
              {/* {!this.state.isV2Enabled ? <TourIReport /> : null} */}

              {!!this._handlePermittedRoute(UAUC) &&
                this.state.currentTab === "soi" && (
                  <NmReport
                    hideFieldList={this.state.soiFormHidingField}
                    reportType="soi"
                    close={this.closeModal}
                    parentStateSetter={this.setState}
                    setClick={(click) => (this.__nmEmitter = click)}
                    sourceList={this.state.sourceList}
                  />
                )}

              {!!this._handlePermittedRoute(NM) &&
                this.state.currentTab === "nm" && (
                  <NmReport
                    hideFieldList={this.state.nmFormHidingField}
                    reportType="nm"
                    close={this.closeModal}
                    parentStateSetter={this.setState}
                    setClick={(click) => (this.__nmEmitter = click)}
                  />
                )}

              {!!this._handlePermittedRoute(IRA) &&
                this.state.currentTab === "ira" && (
                  <>
                    <NmReport
                      hideFieldList={this.state.iraFormHidingField}
                      reportType="ira"
                      close={this.closeModal}
                      parentStateSetter={this.setState}
                      setClick={(click) => (this.__nmEmitter = click)}
                    />
                  </>
                )}
              {this.state.currentTab === "gp" && (
                <GPReport
                  hideFieldList={this.state.iraFormHidingField}
                  reportType="gp"
                  close={this.closeModal}
                  parentStateSetter={this.setState}
                  setClick={(click) => (this.__gpEmitter = click)}
                />
              )}
              {this.state.currentTab === "trn" && (
                <TrainingReport
                  hideFieldList={this.state.iraFormHidingField}
                  reportType="training"
                  close={this.closeModal}
                  parentStateSetter={this.setState}
                  setClick={(click) => (this.__trainingEmitter = click)}
                  isFromAction={this.props.isFromAction || false}
                  formActionData={this.props.formActionData || {}}
                />
              )}
            </Modal.Body>
          </Scrollbars>
          {this.state.isV2Enabled ? (
            <Modal.Footer className="d-flex justify-content-center ">
              <div className="d-flex-center gap-5">
                <button onClick={this.closeModal} className="border btn mx-2 p-1 clide-v2-fs-4">{this.props.t('cancel')}</button>
                <button
                  
                  onClick={() =>
                    this.debounceFunc(() => {
                      this.__evenetEmitter(this.state.currentTab)
                    })
                  }
                  disabled={this.state?.apiLoading}
                  className="border clide-v2-common-orange-bg-1 clide-v2-common-white-1 clide-v2-fs-4 font-weight-500 px-2 py-1 rounded"
                >
                  {this.state?.apiLoading ? this.state.t('loading') : this.props.t('report')}
                </button>
              </div>
            </Modal.Footer>
          ) : null}
        </Modal>
      </div>
    );
  }
}

export default compose(connect(mapStateToProps, null),withTranslation(["workpermit", "util"]))(ReportModal);


export const HorizontalNavTab = ({
  tabs = [],
  activeItem,
  handleChange,
  classes = "",
  extraValue = {}
}) => {
  const items = tabs.filter(({ skip = false, keyClass = "", ...rest }) => {
    if (skip === false) {
      return rest;
    }
  });
  return (
    <ul className={`horizontal-nav-tab-wrapper ${classes}`}>
      {items.map((el) => (
        <li
          key={el.key}
          onClick={() => handleChange(el.key)}
          className={`tab-item d-flex align-items-center ${el?.keyClass} ${
            activeItem === el?.key ? "tab-active" : ""
          }`}
        >
          <a id={el.key}>{el?.tabName}  <div className="ml-2">{extraValue[el?.key]}</div> </a>
          <div className="mx-1">
            {el?.activeIcon !== undefined && activeItem === el?.key
              ? el?.activeIcon
              : el?.icon}
          </div>
        </li>
      ))}
    </ul>
  );
};
