import React, { Component } from "react";
import { Button } from "react-bootstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { toast } from "react-toastify";
import { Redirect, withRouter } from "react-router-dom";
import Url from "app/shared/constants/Url";
import axios from "axios";
import {
  SuccessToast,
  ErrorToast,
} from "app/shared/components/Popups/ToastMessage";
import ClideLoader from "app/shared/ui-kit/loader";
import AlertMessage from "app/shared/components/Popups/AlertMessage";
const qs = require("qs");

const alertMsg = (props) => {
  toast.error(props.message, {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    showHidePassword: false,
  });
};

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      linkExpired: false,
      token: this.props.match.params.token,
      flag: this.props.match.params.flag,
      formSubmitting: false,
      redirectToReferrer: false,
      email: null,
      emailError: false,
      showOtpSection: false, 
      enteredOtp: "",
      prevPath: null,
      otp: ["", "", "", "", "", ""], 
      isResendDisabled: true, 
      timer: 15, 
      loginType: 1,
    };
  }

  componentDidMount() {
    this._checkLinkExpiry();
  }

  _getLogo = async () => {
    const requestData = {
      status: 1,
      link: Url.clientURL,
    };
    import("app/shared/utility/UtilFunction").then((utilFunction) => {
        let responseObj = utilFunction.sharedAxiosAPI(
          Url.compnayLogo,
          undefined,
          requestData
        );

        responseObj
            .then(async (response) => {
                if (response.data.status === 200) {
                    const loginType = response.data.data.login_type;
                    this.setState({ loginType : loginType });

                    if (loginType === 3) {
                        this.setState({ showOtpSection: true });
                        this.otpGenerateResetPassword();
                    } else {
                        this.setState({ showOtpSection: false });
                    }
                }
            })
            .catch((error) => console.log(error));
    });
};

  _checkLinkExpiry = () => {
    axios
      .post(Url.linkExpire, qs.stringify({ token: this.state.token }), {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
      .then((res) => {
        console.log("Link expiry :", res);
        if (res.data.msg === "Link Expired") {
          this.setState({ linkExpired: true });
        } else if (res.data.status === 401) {
          this.setState({ linkExpired: true });
        } else {
          this._getLogo();
          this.setState({ linkExpired: false });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  _handleResendLink = async () => {
    if (this._validEmail(this.state.email)) {
      await axios
        .post(
          Url.resendLink,
          qs.stringify({
            email: this.state.email,
          }),
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        )
        .then((res) => {
          if (res.data.status === 200) {
            SuccessToast(
              "Resend password link successfully! please check your email..."
            );
            setTimeout(() => {
              this.setState({ redirectToReferrer: true });
            }, 2000);
          } else {
            ErrorToast(res.data.msg);
          }
          this.setState({ formSubmitting: false });
        })
        .catch((error) => {
          console.log(error);
          this.setState({ formSubmitting: false });
        });
    } else {
      await this.setState({ emailError: true });
    }
  };

  _validEmail = (email) => {
    let emailRegex = /^\b[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b$/i;
    if (email && email.match(emailRegex)) {
      return true;
    }
    return false;
  };

  _handleEmailChange = async (event) => {
    let em = event.target.value;
    await this.setState({ email: em, emailError: em ? false : true });
  };

  verifyOtp = () => {
    this.setState({ showOtpSection: false });
    console.log("Updated OTP:", this.state.enteredOtp);
  };

otpGenerateResetPassword = async () => {
  this.startResendTimer();
  let requestData = {
    api_key: this.state.token,
  };

  let URL = Url.otpGenerateReset;
  const contentType = {
    "Content-Type": "application/x-www-form-urlencoded",
  };

  await axios
    .post(URL, qs.stringify(requestData), { headers: contentType })
    .then(async (response) => {
      if (response.data.status === 200) {
        this.setState({ 
          tempAuthKey: response.data.data.tempAuthKey,
          userId: response.data.data.user_id,
        });
      } else {
        this.setState({ formSubmitting: false });
      }
    })
    .catch(async (error) => {
      this.setState({ dataLoading: false });
      console.error("Error in OTP generation:", error);
    });
};

    resendOtp = async () => {
      this.startResendTimer();
      let requestData = {
        temp_authkey: this.state.tempAuthKey,
        user_id: this.state.userId,
      };

      let URL = Url.resendOtp;
      const contentType = {
        "Content-Type": "application/x-www-form-urlencoded",
      };

      console.log("Request Payload:", requestData);

      await axios
        .post(URL, qs.stringify(requestData), { headers: contentType })
        .then(async (response) => {
          if (response.data.status === 200) {
            AlertMessage({ message: "OTP has been sent", type: "success" });
          } else {
            console.error("Error in OTP resend:", response.data);
            this.setState({ formSubmitting: false });
          }
        })
        .catch(async (error) => {
          this.setState({ dataLoading: false });
        });
    };

  startResendTimer = () => {
    this.setState({ isResendDisabled: true, timer: 15 });
    const interval = setInterval(() => {
      this.setState((prevState) => {
        if (prevState.timer === 1) {
          clearInterval(interval);
          return { isResendDisabled: false, timer: 0 };
        }
        return { timer: prevState.timer - 1 };
      });
    }, 1000);
  };

  handleChange = (index, event) => {
    const value = event.target.value;
    if (!/^\d?$/.test(value)) return; 

    let newOtp = [...this.state.otp];
    newOtp[index] = value;
    this.setState({ otp: newOtp, enteredOtp: newOtp.join("") });

    if (value && index < 5) {
      document.getElementById(`otp-input-${index + 1}`).focus();
    }

  };

  handleKeyDown = (index, event) => {
    if (event.key === "Backspace") {
      let newOtp = [...this.state.otp];

      if (!newOtp[index] && index > 0) {
        document.getElementById(`otp-input-${index - 1}`).focus();
      }

      newOtp[index] = ""; 
      this.setState({ otp: newOtp });
    }
  };

  handleResend = () => {
    console.log("Resending OTP...");
    this.startResendTimer();
  };



  render() {
    if (this.state.redirectToReferrer) {
      return <Redirect to={"/auth/login"} />;
    }
    if (this.state.formSubmitting) {
      return <ClideLoader />;
    }

    if (this.state.linkExpired) {
      return (
        <div>
          <h4 className="session-expire">
            The password reset link is no longer valid. Please request another
            password reset email.
            <p>Please enter email id, which is used at time of registration</p>
          </h4>

          <div className="login-form">
            <h6> Resend Password </h6>
            <div className="floatingLabelInput">
              <label className={this.state.email ? "on" : null}>Email Id</label>
              <input
                name="email"
                type="email"
                placeholder="Enter previous email id"
                onChange={this._handleEmailChange}
              />
              {!!this.state.emailError && (
                <p className="text-danger"> Please enter valid email </p>
              )}
            </div>
            <div className="login-btn">
              <Button
                type="submit"
                className={"red-btn"}
                onClick={this._handleResendLink}
              >
                {this.state.formSubmitting ? "Please wait..." : "SUBMIT"}
              </Button>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="login-form">
          {this.state.flag == 2 ? (
            <h4>Reset Password</h4>
          ) : (
            <h4>Set Password</h4>
          )}
          {this.state.showOtpSection ? (
            <div style={{ textAlign: "center", marginTop: "20px" }}>
            <h3 style={{ fontSize: "18px", fontWeight: "bold", color: "#333", marginBottom: "10px" }}>
              Enter OTP
            </h3>
            
            {/* OTP Input Fields in a Single Row */}
            <div style={{ display: "flex", justifyContent: "center", gap: "8px" }}>
              {this.state.otp.map((digit, index) => (
                <input
                  key={index}
                  id={`otp-input-${index}`}
                  type="text"
                  value={digit}
                  onChange={(e) => this.handleChange(index, e)}
                  onKeyDown={(e) => this.handleKeyDown(index, e)}
                  maxLength={1}
                  style={{
                    width: "40px",
                    height: "40px",
                    fontSize: "20px",
                    textAlign: "center",
                    border: "1px solid #ccc",
                    borderRadius: "5px",
                    outline: "none",
                    transition: "all 0.2s ease-in-out"
                  }}
                  onFocus={(e) => (e.target.style.borderColor = "#007bff")}
                  onBlur={(e) => (e.target.style.borderColor = "#ccc")}
                />
              ))}
            </div>
          
            {/* Verify OTP Button */}
            <button
            onClick={this.verifyOtp}
            disabled={this.state.otp.includes("")}  // Button disabled if any field is empty
            style={{
              marginTop: "15px",
              padding: "10px 15px",
              fontSize: "16px",
              border: "none",
              borderRadius: "5px",
              cursor: this.state.otp.includes("") ? "not-allowed" : "pointer",
              backgroundColor: this.state.otp.includes("") ? "#ccc" : "#ff644e",
              color: "white"
            }}
          >
            Verify OTP
          </button>
          
            {/* Resend OTP Button with Timer */}
            <button
              onClick={this.resendOtp}
              disabled={this.state.isResendDisabled}
              style={{
                marginTop: "10px",
                marginLeft: "10px",
                padding: "10px 15px",
                fontSize: "16px",
                border: "none",
                borderRadius: "5px",
                cursor: this.state.isResendDisabled ? "not-allowed" : "pointer",
                backgroundColor: this.state.isResendDisabled ? "#ccc" : "#28a745",
                color: "white"
              }}
            >
                Resend OTP 
                {this.state.isResendDisabled ? `(${this.state.timer}s)` : ""}
              </button>
            </div>
          )
            :(
              <Formik
                initialValues={{ confirmPassword: "", password: "" }}
                validate={(values) => {
                  let errors = {};
                  if (values.password === "") {
                    errors.password = "New Password is required";
                  } else if (
                    !/^(?=.*\d)(?=.*[A-Za-z])[A-Za-z\d$@$!%*#?&]{8,}$/.test(
                      values.password
                    )
                  ) {
                    errors.password =
                      "Invalid password format (Password should contain minimum 8 and maximun 16 characters with atleast 1 number and 1 special characters)";
                  }
                  if (values.confirmPassword === "") {
                    errors.confirmPassword = "Confirm Password is required";
                  } else if (values.password !== values.confirmPassword) {
                    errors.confirmPassword =
                      "New Password and Confirm does not match";
                  }
                  return errors;
                }}
                onSubmit={async (values, actions) => {
                  this.setState({ formSubmitting: true },

                    await axios
                      .post(
                        Url.setpassword,
                        qs.stringify(this.state.loginType == 3 ?
                          {
                            token: this.state.token,
                            password: values.password,
                            type: this.state.loginType,
                            temp_auth: this.state.tempAuthKey,
                            user_id: this.state.userId,
                            otp: this.state.otp.join(""),
                          }
                          :
                          {
                            token: this.state.token,
                            password: values.password,
                            type: this.state.loginType,
                          }
                        ),
                        {
                          headers: {
                            "Content-Type": "application/x-www-form-urlencoded",
                          },
                        }
                      )
                      .then((res) => {
                        if (res.data.status === 200) {
                          SuccessToast(
                            "Your password has been set successfully! Redirecting to Login..."
                          );
                          setTimeout(() => {
                            this.setState({ redirectToReferrer: true });
                          }, 2000);
                        } else {
                          ErrorToast("Password not set. Please try again.");
                        }
                        this.setState({ formSubmitting: false });
                      })
                      .catch((error) => {
                        console.log(error);
                        this.setState({ formSubmitting: false });
                      }));

                  this.setState({ formSubmitting: false });
                }}
              >
                {({
                  touched,
                  errors,
                  isSubmitting,
                  handleSubmit,
                  isValid,
                  dirty,
                  values,
                }) => (
                  <Form onSubmit={handleSubmit} className="input-container">
                    <div className="input-group">

                      <Field
                        name="password"
                        type="password"
                        placeholder="New Password"
                        className="w-100 border-0"
                      />
                      <ErrorMessage
                        component="div"
                        name="password"
                        className="inputErrorMsg text-danger"
                      />
                    </div>
                    <div className="input-group">

                      <Field
                        name="confirmPassword"
                        type="password"
                        placeholder="Confirm Password"
                        className="w-100 border-0"
                      />
                      <ErrorMessage
                        component="div"
                        name="confirmPassword"

                        className="inputErrorMsg text-danger"
                      />
                    </div>

                    <button
                      type="submit"

                      disabled={!isValid || this.state.formSubmitting || !dirty}
                    >
                      {this.state.formSubmitting ? "Please wait..." : "SUBMIT"}
                    </button>

                  </Form>
                )}
              </Formik>
          )}
        </div>
      );
    }
  }
}

export default withRouter(ResetPassword);
