import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
//assets
import { useTranslation, withTranslation } from "react-i18next";

// const {t} = useTranslation('uauc');
import { RoundDelete, RoundEdit, TeamAdd, PolicyIcon, DdIcon, ElearnIcon, ElearnIconOrange, DdIconOrange, PolicyIconOrange } from "assets/Iconsv2/Iconsv2";


const DetailTimelineSection = ({
  data = [],
  state,
  url,
  functionContent,
  variableData,
}) => {
  const {t} = useTranslation(['uauc','util']);
  const reportData = state.reportDetail;
  const { isApproveEnabled, isReportedUserEnabled } = variableData ?? {};
  const [showModalImage, setShowModalImage] = useState(false);
  const [showModalVideo, setShowModalVideo] = useState(false);
  const [showModalPdf, setShowModalPdf] = useState(false);
  const handleShowImage = () => {setShowModalImage(true);}
  const handleCloseImage = () => setShowModalImage(false);
  const handleShowVideo = () => {setShowModalVideo(true);}
  const handleCloseVideo = () => setShowModalVideo(false);
  const handleShowPdf= () => {setShowModalPdf(true);}
  const handleClosePdf = () => setShowModalPdf(false);
  

  


  return (
    <div className="row  clide-v2-colume_title ">
      <div className="mb-4 clide-v2-fs-3 fw-bold">{t('uaUCDt')}</div>
      <div className="row my-4 align-items-center">
        <div className="col-12 col-lg-4 col-md-4 px-0">
          <Link to={url}>
            <Button
              className="border clide-v2-primary clide-v2-white-color"
              variant=""
            >
              <i className="fa fa-angle-left" aria-hidden="true"></i>
            </Button>
          </Link>
        </div>
        <div className="col-12 col-lg-5 col-md-4 ">
          <div>
            <span className="common-black-1 font-weight-500">
              {" "}
              {state.soiId ?? ""}{" "}
              <span
                style={{
                  borderLeft: "3px Solid Orange",
                  padding: "0 5px 0 5px",
                }}
              >
                {" "}
                {reportData.act_type} - {reportData.activity}{" "}
              </span>
              <span
                style={{
                  borderLeft: "3px Solid Orange",
                  padding: "0 5px 0 5px",
                }}
              >
                {reportData.reportedmonth_name}
              </span>
            </span>
          </div>
        </div>
        <div className="col-12 col-lg-3 col-md-4 d-flex justify-content-end px-0">
          <div className="d-flex align-items-center">

            <>
              {state?.activity_img ? (
                <div>
                  <button className="clide-v2-icon-btn mr-2"
                    title={t('dd')}
                    onClick={handleShowImage} >
                    <DdIconOrange />
                  </button>
                  <Modal
                    show={showModalImage}
                    onHide={handleCloseImage}
                    className="reportPopup "
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>{t('doDonts')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <img src={state.activity_img} alt="">
                      </img>
                    </Modal.Body>
                  </Modal>
                </div>
              ) : null}

              {state?.activity_video ? (
                <div>
                  <button className="clide-v2-icon-btn mr-2"
                    title={t('eLearn')}
                    onClick={handleShowVideo} >
                    <ElearnIconOrange />
                  </button>
                  <Modal
                    show={showModalVideo}
                    onHide={handleCloseVideo}
                    className="reportPopup "
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>{t('eLearn')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                      <video
                        controls
                        width="100%"
                        style={{ borderRadius: "8px" }}
                        src={state.activity_video}
                      > </video>
                    </Modal.Body>
                  </Modal>
                </div>
              ) : null}

              {state?.activity_pdf ? (
                <div>
                  <button className="clide-v2-icon-btn mr-2"
                    title={t('policy')}
                    onClick={handleShowPdf} >
                    <PolicyIconOrange />
                  </button>
                  <Modal
                    show={showModalPdf}
                    onHide={handleClosePdf}
                    className="reportPopup "
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>{t('policy')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <iframe
                        src={`${state.activity_pdf}#toolbar=0`}
                        type="application/pdf"
                        width="100%"
                        height="500px"
                        title={t('pdfViewer')}
                        style={{ borderRadius: "8px" }}
                      />
                    </Modal.Body>
                  </Modal>
                </div>
              ) : null}

                {reportData.edit_flag == 1 && (
                  <Link
                    to={`/edit-reporting-detail/${reportData.id}/${reportData.service_id}/${reportData.project_id}/edit`}
                  >
                    <button className="clide-v2-icon-btn mr-2" title={t('edit')}>
                      <RoundEdit />
                    </button>
                  </Link>
                )}

                {reportData.delete_status == 1 ? (
                  <div>
                    <button
                      className="clide-v2-icon-btn mr-2"
                      title={t('delete')}
                      onClick={() => functionContent.showModal()}
                    >
                      <RoundDelete />
                    </button>
                  </div>
                ) : null}
              </>
            

            <div>


              {
              (isApproveEnabled || isReportedUserEnabled) &&
              state.resolveFlag == "open" ? (
                <button
                  className="clide-v2-icon-btn mr-2"
                  title={t('addTeam')}
                  onClick={functionContent.openInviteUser}
                >
                  <TeamAdd />
                </button>
              ) : null}
              {state.resolveFlag == "resolve" ? (
                <span className="common-black-1 font-weight-500">
                  {" "}
                  {t('resolved')}{" "}
                </span>
              ) : (
                <>
                  {reportData.closing_tab_display == 1 ? (
                    <button
                      className="  border btn clide-v2-active px-2 py-1"
                      title={t('resolve')}
                      onClick={functionContent.openResolve}
                    >
                      {t('resolve')}
                    </button>
                  ) : null}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="pe-3 clide-v2-p-0">
        <div className="clide-v2-card-box-1 p-3 ">
          <div className="text-center clide-v2-primary-color py-4">
            {t('timeline')}
          </div>
          <div className="overflow-x overflow-auto">
            <div
              className="line_box stav_projektu"
              style={{ margin: "40px 0 40px 0" }}
            >
              {data?.map((el) => {
                return (
                  <div className="text_circle done ">
                    <a href="javascript:void(0)" className="tvar">
                      <span />
                    </a>
                    <div className="circle clide-v2-white-box h-75">
                      {el?.title}
                      <hr />
                      {el?.cardTitle}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(DetailTimelineSection);
