import React, { Component } from "react";
import { Button } from 'react-bootstrap';
import { ArrowLeftCircle } from 'react-feather';
import { Link } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { toast } from 'react-toastify';
import Url from 'app/shared/constants/Url';
import axios from 'axios';
import { withTranslation } from 'react-i18next';
const qs = require('qs');

const alertMsg = (props) => {
  if(props.type == 'error') {
    toast.error(props.message,{
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false
    });
  } else {
    toast.success(props.message,{
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false
    });
  }
}

  export default withTranslation('util')(
    class ForgotPassword extends Component {

  state = {
    formSubmitting: false
  }
  
  render() {
    const {t} = this.props;
    return (
      <div className="login-form">
        
        <div className="sign-header-section">
          <div className="sign-in-title" style={{ padding: "5% 0", margin: 0 }}>
          {t('forgotPassword')}
          </div>
        </div>
        <Formik 
          initialValues={{ email: ""}}
          validate={values => {
            let errors = {};
            if (values.email === "") {
              errors.email = t('emailIsRequired');
            } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
              errors.email = t('invalidEmailAddressFormat');
            }
            return errors;
          }}
          onSubmit={async(values, actions) => {
            this.setState({formSubmitting: true})
            axios.post(Url.forgotPassword,
              qs.stringify({
                email: values.email
              }), 
              {
                headers: { 
                  "Content-Type": "application/x-www-form-urlencoded"
                }
              }
            )
            .then(res => { console.log(res);
              if(res.data.status == 200) {
                alertMsg({message: res.data.msg, type: 'success'});
              } else {
                alertMsg({message: res.data.msg, type: 'error'});
              }
              this.setState({formSubmitting: false})
            })
            .catch(error => {
              this.setState({formSubmitting: false})
            })   
          }}
        >
          {({ touched, errors, isSubmitting, handleSubmit, isValid, dirty, values }) => (
            <Form onSubmit={handleSubmit} className="input-container">
              <div className="input-group">
                <Field 
                  name="email"  
                  type="email" 
                  placeholder={t('enterYourRegisteredEmailId')}
                  className="w-100 border-0"
                />
                <ErrorMessage
                  component="div"
                  name="email"
                    className="inputErrorMsg text-danger"
                />
              </div>
                <button 
                  type="submit" 
                  
                  disabled={!isValid || this.state.formSubmitting || !dirty}
                >
                    {this.state.formSubmitting ? t('pleaseWait') : t('submitAUC')}
                </button>
              
              <div className="back-div d-flex justify-content-center align-items-center">
                <ArrowLeftCircle color='#000' size="18" />&nbsp;
                <Link to={`/auth/login`}>{t('backToLogin')}</Link>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    )
  }
})