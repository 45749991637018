/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import { Scrollbars } from "react-custom-scrollbars";
import _ from "lodash";
import { CarouselComponent } from "app/shared/components/ReusableComponents/Carousel";
// ui kit components
//components
import { HorizontalNavTab } from "app/shared/components/ReportModal";
//assets
import {
  PlusIconRed,
  RoundDelete,
  RoundEdit,
  FilterRound,
} from "assets/Iconsv2/Iconsv2";
//constans
import { tabs } from "../../../../../../constants/constants";
import { useTranslation } from "react-i18next";

const DetailStep5 = ({ state, functionContent, variablesObj }) => {
  const {
    isInprogress,
    isApproveEnabled,
    iraDetailStep3,
    iraDetailStep5,
    dataGathering,
    isCompleted,
    isTeamMemeberLoggedIn,iraDetail
    
  } = variablesObj ?? {};
  const { witness, posts = {}, cost = {}, analysis = {} } = dataGathering ?? {};
  const { costList = [] } = iraDetailStep3?.cost_details ?? {};
  const {t} = useTranslation('ira');

  return (
    <div>
      <div className="row mt-3">
        <div className="col-12 ">
          <div className="col-12 col-lg-12 col-md-12 d-flex justify-content-between px-0">
            <div className="clide-v2-title mb-1">
              <label className="clide-v2-fs-4 clide-v2-primary-color fw-bold pt-3 mb-4"> {t('step5Capa')}</label>
            </div>
          </div>
        </div>
        {["pending", "progress"].includes(state?.reportStatus) ? (
          <div className="col-12 ">
            <div className="row">
              <div className="col-12 px-0">
                <div className="row">
                  <div className=" col-12 d-flex-center gap-4 px-0">
                    {!isCompleted ? (
                      <div className="d-flex justify-content-around my-2">
                        <HorizontalNavTab
                          classes="w-100"
                          tabs={tabs(t)}
                          activeItem={state.currentTab}
                          handleChange={(key) =>
                            functionContent.setState({ currentTab: key })
                          }
                          extraValue={{
                            "prop":`(${iraDetail?.basic_ira?.proposedCapaCount ?? 0})`,
                            "final":`(${iraDetail?.basic_ira?.finalCapaCount ?? 0})`
                          }}
                        />
                      </div>
                    ) : null}
                    <div className=" d-flex justify-content-end px-0 gap-2 align-items-center">
                      {isApproveEnabled || isTeamMemeberLoggedIn ? (
                        <button
                          className="clide-v2-icon-btn "
                          title={t('filter')}
                          onClick={() =>
                            functionContent.debounceFunc(() => {
                              functionContent.manageDataGatheringParams(
                                "filterParams",
                                {
                                  isFilterEnable: true,
                                }
                              );
                            })
                          }
                        >
                          <FilterRound width="33" height="33" />
                        </button>
                      ) : null}
                      {state.currentTab == "prop" &&
                      !isCompleted &&
                      (isApproveEnabled || isTeamMemeberLoggedIn) ? (
                        <button
                          className="clide-v2-icon-btn "
                          title="Add123"
                          onClick={() =>
                            functionContent.debounceFunc(() => {
                              functionContent.manageDataGatheringParams(
                                "recommendation",
                                {
                                  isAddEnable: true,
                                }
                              );
                            })
                          }
                        >
                          <PlusIconRed width="30" height="30" />
                        </button>
                      ) : (
                        <div>
                          <button
                            className="btn border clide-v2-primary clide-v2-white-color "
                            title={t('freezeAll')}
                            onClick={() =>
                              functionContent.debounceFunc(() => {
                                functionContent.setState({
                                  isFreezeEnabled: true,
                                });
                              })
                            }
                          >
                            {t('freezeAll')}
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
      {isCompleted ? (
        <Scrollbars
          style={{ height: "30rem" }}
          renderView={(props) => (
            <div
              style={{
                paddingRight: "15px",
                paddingBottom: "1rem",
                ...props.style,
                overflowX: "hidden",
              }}
            />
          )}
        >
          {iraDetailStep5?.freeze_corrective_action?.map((item, idx) => (
            <div className="row mt-3" key={idx}>
              <div className="col-12 col-md-12 col-lg-12">
                <div
                  className="clide-v2-white-box clide-v2-border-radius-capa clide-v2-border-10  mb-3"
                  style={{ borderColor: `${item.priority_color}` }}
                >
                  <div className="w-75 p-3">
                    <label className="clide-v2-fs-4 fw-semibold ">
                      {" "}
                      {item.priority}&nbsp; | {item?.category || ""} &nbsp; |
                      &nbsp;
                      {` ${item.recommendation?.[0].capaType}` || ""}
                    </label>
                  </div>

                  <div className="mb-1">
                    <div className="row p-1">
                      <div className="col-12 col-md-6 col-lg-3">
                        <div className="clide-v2-title mb-1">
                          <label className="clide-v2-fs-4">{t('activity')} </label>
                        </div>
                        <div className="clide-v2-title mb-1">
                          <label className="">{item.activity}</label>
                        </div>
                      </div>

                      <div className="col-12 col-md-6 col-lg-3">
                        <div className="clide-v2-title mb-1">
                          <label className="clide-v2-fs-4">{t('closedOn')}</label>
                        </div>
                        <div className="clide-v2-title mb-1">
                          {[
                            ...(item?.recommendation ??
                              item?.responsible_person),
                          ]?.map((data, idx) => (
                            <label key={idx}>{data.closing_date ?? "NA"}</label>
                          ))}
                        </div>
                      </div>

                      <div className="col-12 col-md-6 col-lg-3">
                        <div className="clide-v2-title mb-1">
                          <label className="clide-v2-fs-4">{t('dueDate')}</label>
                        </div>
                        <div className="clide-v2-title mb-1">
                          <label className=""> {item.target_date}</label>
                        </div>
                      </div>

                      <div className="col-12 col-md-6 col-lg-3">
                        <div className="clide-v2-title mb-1">
                          <label className="clide-v2-fs-4">
                            {t('corrective')}{" "}
                          </label>
                        </div>
                        <div className="clide-v2-title mb-1">
                          <label className="">
                            {` ${item.recommendation?.[0]?.recommendation}` ||
                              ""}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-12 col-md-6 col-lg-8 ">
                        <div className="row p-0">
                          <div className="col-12 col-md-6 col-lg-6 p-0">
                            <div className="clide-v2-title mb-1">
                              <label className="clide-v2-fs-4">
                                {t('proposed')}{" "}
                              </label>
                            </div>
                            <img
                              className="clide-v2-profile-img mr-1"
                              src={item.recommended_by_pic}
                            />
                            <label className="me-2 clide-v2-fs-5 fw-semibold">
                              {item.recommended_by}
                            </label>
                          </div>
                          <div className="col-12 col-md-6 col-lg-6 p-0">
                            <div className="clide-v2-title mb-1">
                              <label className="clide-v2-fs-4">
                                {t('assigned')}{" "}
                              </label>
                              <Scrollbars
                                style={{
                                  height: "2rem",
                                }}
                                renderView={(props) => (
                                  <div
                                    style={{
                                      paddingRight: "15px",

                                      ...props.style,
                                      overflowX: "hidden",
                                    }}
                                  />
                                )}
                              >
                                {" "}
                                {[
                                  ...(item?.responsible_person_list ??
                                    item?.responsible_person),
                                ]?.map((data) => (
                                  <div>
                                    <img
                                      src={data.person_pic}
                                      className="clide-v2-profile-img"
                                      alt="user-avatar"
                                    />{" "}
                                    <span className="w-max">
                                      {data.person_name}
                                    </span>{" "}
                                  </div>
                                ))}{" "}
                              </Scrollbars>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-12 col-lg-12 mt-3 px-0">
                          <div className="clide-v2-title mb-1">
                            <label className="clide-v2-fs-4">
                              {t('closingRemark')}{" "}
                            </label>
                          </div>
                          <div className="clide-v2-title mb-1">
                            <label className="">
                              {item.recommendation?.[0].observation || "N/A"}
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-md-6 col-lg-4 mb-2">
                        <CarouselComponent
                          items={
                            item.recommendation?.[0]?.recommendation_evidence
                              .length > 0
                              ? [
                                  {
                                    url: item.recommendation?.[0]
                                      .recommendation_evidence[0]?.evidence,
                                    contentType: "image",
                                  },
                                ]
                              : []
                          }
                          showArrows={false}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Scrollbars>
      ) : state.currentTab == "prop" ? (
        <>
          <Scrollbars
            style={{ height: "30rem" }}
            renderView={(props) => (
              <div
                style={{
                  paddingRight: "15px",
                  paddingBottom: "1rem",
                  ...props.style,
                  overflowX: "hidden",
                }}
              />
            )}
          >
            {iraDetailStep5?.praposed_corrective_action?.map(
              (item, idx, arr) => (
                <div className="row mt-3" key={idx}>
                  <div className="col-12 col-md-12 col-lg-12">
                    <div
                      className="clide-v2-white-box clide-v2-border-radius-capa clide-v2-border-10  mb-3"
                      style={{ borderColor: `${item.priority_color}` }}
                    >
                      <div className="align-items-center p-3 px-1 row">
                        <div className="col-6 ">
                          <label className="clide-v2-fs-4 fw-semibold mb-0">
                            {" "}
                            {item.priority}&nbsp; | {item?.category || ""}{" "}
                            &nbsp; | &nbsp;
                            {` ${item.recommendation?.[0].capaType}` || ""}
                          </label>
                        </div>
                        <div className="col-6 ">
                          <div className="d-flex space-between mt-2 justify-content-end gap-2">
                            {isApproveEnabled ? (
                              <button
                                className="btn border clide-v2-primary clide-v2-white-color "
                                title={t('freezeAll')}
                                onClick={() =>
                                  functionContent.debounceFunc(() => {
                                    functionContent.manageDataGatheringParams(
                                      "recommendation",
                                      {
                                        approveId: item.recommended_id,
                                      }
                                    );
                                  })
                                }
                              >
                                {t("approve")}
                              </button>
                            ) : null}
                            {isInprogress && isApproveEnabled ? (
                              <div>
                                <button
                                  onClick={() =>
                                    functionContent.debounceFunc(() => {
                                      functionContent.manageEdit(
                                        item.recommended_id,
                                        item,
                                        arr
                                      );
                                    })
                                  }
                                  className="clide-v2-icon-btn "
                                >
                                  <RoundEdit />
                                </button>
                              </div>
                            ) : null}

                            {isInprogress && isApproveEnabled ? (
                              <div>
                                <button
                                  onClick={() =>
                                    functionContent.debounceFunc(() => {
                                      functionContent.manageDataGatheringParams(
                                        "recommendation",
                                        {
                                          deleteId: item.recommended_id,
                                          isPropEnable: true,
                                        }
                                      );
                                    })
                                  }
                                  className="clide-v2-icon-btn "
                                >
                                  <RoundDelete />
                                </button>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>

                      <div className="mb-1">
                        <div className="row p-1">
                          <div className="col-12 col-md-6 col-lg-3">
                            <div className="clide-v2-title mb-1">
                              <label className="clide-v2-fs-4">{t('activity')}</label>
                            </div>
                            <div className="clide-v2-title mb-1">
                              <label className="">{item.activity}</label>
                            </div>
                          </div>

                          <div className="col-12 col-md-6 col-lg-3">
                            <div className="clide-v2-title mb-1">
                              <label className="clide-v2-fs-4">{t('dueDate')}</label>
                            </div>
                            <div className="clide-v2-title mb-1">
                              <label className=""> {item.target_date}</label>
                            </div>
                          </div>

                          <div className="col-12 col-md-6 col-lg-3">
                            <div className="clide-v2-title mb-1">
                              <label className="clide-v2-fs-4">
                                {t('corrective')}{" "}
                              </label>
                            </div>
                            <div className="clide-v2-title mb-1">
                              <label className="">
                                {` ${item.recommendation?.[0]?.recommendation}` ||
                                  ""}
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="row my-3">
                          <div className="col-12 col-md-6 col-lg-8 ">
                            <div className="row p-0 mb-3">
                              <div className="col-12 col-md-6 col-lg-6 p-0">
                                <div className="clide-v2-title mb-1">
                                  <label className="clide-v2-fs-4">
                                    {t('proposed')}{" "}
                                  </label>
                                </div>
                                <img
                                  className="clide-v2-profile-img mr-1"
                                  src={item.recommended_by_pic}
                                />
                                <label className="me-2 clide-v2-fs-5 fw-semibold">
                                  {item.recommended_by}
                                </label>
                              </div>
                              <div className="col-12 col-md-6 col-lg-6 p-0">
                                <div className="clide-v2-title mb-1">
                                  <label className="clide-v2-fs-4">
                                    {t("assigned")}{" "}
                                  </label>
                                  <Scrollbars
                                    style={{
                                      height: "2rem",
                                    }}
                                    renderView={(props) => (
                                      <div
                                        style={{
                                          paddingRight: "15px",

                                          ...props.style,
                                          overflowX: "hidden",
                                        }}
                                      />
                                    )}
                                  >
                                    {" "}
                                    {[
                                      ...(item?.responsible_person_list ??
                                        item?.responsible_person),
                                    ]?.map((data) => (
                                      <div>
                                        <img
                                          src={data.person_pic}
                                          className="clide-v2-profile-img"
                                          alt={t('userAvatar')}
                                        />{" "}
                                        <span className="w-max">
                                          {data.person_name}
                                        </span>{" "}
                                      </div>
                                    ))}{" "}
                                  </Scrollbars>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 col-md-6 col-lg-4 mb-2"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            )}
          </Scrollbars>
        </>
      ) : (
        <Scrollbars
          style={{ height: "30rem" }}
          renderView={(props) => (
            <div
              style={{
                paddingRight: "15px",
                paddingBottom: "1rem",
                ...props.style,
                overflowX: "hidden",
              }}
            />
          )}
        >
          {iraDetailStep5?.final_corrective_action?.map((item, idx) => (
            <div className="row mt-3" key={idx}>
              <div className="col-12 col-md-12 col-lg-12">
                <div
                  className="clide-v2-white-box clide-v2-border-radius-capa clide-v2-border-10  mb-3"
                  style={{ borderColor: `${item.priority_color}` }}
                >
                  <div className="align-items-center p-3 px-1 row">
                    <div className="col-6 ">
                      <label className="clide-v2-fs-4 fw-semibold mb-0">
                        {" "}
                        {item.priority}&nbsp; | {item?.category || ""} &nbsp; |
                        &nbsp;
                        {` ${item.recommendation?.[0].capaType}` || ""}
                      </label>
                    </div>
                    <div className="col-6 ">
                      <div className="d-flex space-between mt-2 justify-content-end gap-2">
                        {isApproveEnabled ? (
                          <div>
                            <button
                              onClick={() =>
                                functionContent.debounceFunc(() => {
                                  functionContent.manageDataGatheringParams(
                                    "recommendation",
                                    {
                                      deleteId: item.recommended_id,
                                    }
                                  );
                                })
                              }
                              className="clide-v2-icon-btn "
                            >
                              <RoundDelete />
                            </button>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>

                  <div className="mb-1">
                    <div className="row p-1">
                      <div className="col-12 col-md-6 col-lg-3">
                        <div className="clide-v2-title mb-1">
                          <label className="clide-v2-fs-4">{t('activity')} </label>
                        </div>
                        <div className="clide-v2-title mb-1">
                          <label className="">{item.activity}</label>
                        </div>
                      </div>

                      <div className="col-12 col-md-6 col-lg-3">
                        <div className="clide-v2-title mb-1">
                          <label className="clide-v2-fs-4">{t('dueDate')}</label>
                        </div>
                        <div className="clide-v2-title mb-1">
                          <label className=""> {item.target_date}</label>
                        </div>
                      </div>

                      <div className="col-12 col-md-6 col-lg-3">
                        <div className="clide-v2-title mb-1">
                          <label className="clide-v2-fs-4">
                            {t('corrective')}{" "}
                          </label>
                        </div>
                        <div className="clide-v2-title mb-1">
                          <label className="">
                            {` ${item.recommendation?.[0]?.recommendation}` ||
                              ""}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="row my-3">
                      <div className="col-12 col-md-6 col-lg-8 ">
                        <div className="row p-0 mb-3">
                          <div className="col-12 col-md-6 col-lg-6 p-0">
                            <div className="clide-v2-title mb-1">
                              <label className="clide-v2-fs-4">
                               {t('proposed')}{" "}
                              </label>
                            </div>
                            <img
                              className="clide-v2-profile-img mr-1"
                              src={item.recommended_by_pic}
                            />
                            <label className="me-2 clide-v2-fs-5 fw-semibold">
                              {item.recommended_by}
                            </label>
                          </div>
                          <div className="col-12 col-md-6 col-lg-6 p-0">
                            <div className="clide-v2-title mb-1">
                              <label className="clide-v2-fs-4">
                                {t('assigned')}{" "}
                              </label>
                              <Scrollbars
                                style={{
                                  height: "2rem",
                                }}
                                renderView={(props) => (
                                  <div
                                    style={{
                                      paddingRight: "15px",

                                      ...props.style,
                                      overflowX: "hidden",
                                    }}
                                  />
                                )}
                              >
                                {" "}
                                {[
                                  ...(item?.responsible_person_list ??
                                    item?.responsible_person),
                                ]?.map((data) => (
                                  <div>
                                    <img
                                      src={data.person_pic}
                                      className="clide-v2-profile-img"
                                      alt={t('userAvatar')}
                                    />{" "}
                                    <span className="w-max">
                                      {data.person_name}
                                    </span>{" "}
                                  </div>
                                ))}{" "}
                              </Scrollbars>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-md-6 col-lg-4 mb-2"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Scrollbars>
      )}
    </div>
  );
};

export default DetailStep5;
